import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../environments/environment';
import * as models from "./data.modal";

@Injectable({
  providedIn: 'root'
})
export class DataService {
  basePath: any = 'https://rst.intellibooks.io';
  cookieValue: any = '';
  constructor(
    private http: HttpClient,
    private cookieService: CookieService) {
    this.basePath = environment.basePath;
  }


  getHeader(): HttpHeaders {
    // let headers = new HttpHeaders();
    this.cookieValue = this.cookieService.get("SESSIONID");
    let headers = new HttpHeaders()
      .set("Accept", "application/json")
      .set("Content-Type", "application/json");

    if (this.cookieService.get("refresh_token") && this.cookieValue) {
      if (this.cookieService.get("refresh_token") && this.cookieValue) {
        headers = headers.set("Authorization", "Bearer " + this.cookieValue)
      }
    }

    // .set("AuthorizationRefresh", "Bearer " + this.cookieValue)
    return headers;
  }

  checkTenentDB(): Observable<any> {
    let headers = this.getHeader();
    return this.http.get<any>(`${this.basePath}/api/CheckTenent`, { headers });
  }

  getSubdomainDetails(): Observable<any> {
    const headers = this.getHeader();
    return this.http.get<any>(`${this.basePath}/api/SubdomainDetails`, { headers })
  }
  getTokens(): Observable<any> {
    const headers = this.getHeader();
    let data = { refreshToken: this.cookieService.get("refresh_token") }
    return this.http.post<any>(`${this.basePath}/api/refreshToken`, data)
  }

  getFyList(company_id: string | 'null', deviceId: any): Observable<any> {
    let headers = this.getHeader();
    return this.http.get<any>(`${this.basePath}/api/companyFY/${encodeURIComponent(company_id)}/${encodeURIComponent(deviceId)}`, { headers });

  }

  getMenuPermissionList(): Observable<any> {
    let headers = this.getHeader();
    return this.http.get<any>(`${this.basePath}/api/MenuPermission`, { headers });

  }

  get_last_db_backup(): Observable<any> {
    let headers = this.getHeader();
    return this.http.get<any>(`${this.basePath}/api/LastBackup`, { headers });
  }

  login(data: any) {
    let headers = this.getHeader();
    return this.http.post<any>(`${this.basePath}/api/login`, data,
      {
        headers,
      });
  }


  private theme = new BehaviorSubject<boolean>(false);

  settheme(n: any) {
    this.theme.next(n);
  }
  gettheme(): Observable<any> {
    return this.theme.asObservable();
  }

  getFy(fy_id: string | 'null'): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(`${this.basePath}/api/FY/${encodeURIComponent(fy_id)}`, { headers });
  }


  closeFy(company_id: string | 'null', fy_id: string | 'null', data: any): Observable<any> {
    let headers = this.getHeader();

    return this.http.post<any>(`${this.basePath}/api/CloseFY/${encodeURIComponent(company_id)}/${encodeURIComponent(
      fy_id
    )}`, data,
      {
        headers,
      });
  }


  //Backup Database service
  createDBBackup(): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set("Accept", "application/zip");
    headers = headers.set("Content-Type", "application/zip");
    this.cookieValue = this.cookieService.get("SESSIONID");
    // headers = headers.set("Authorization", "bearer " + this.cookieValue);
    headers = headers.set("Authorization", "Bearer " + this.cookieValue)

    return this.http.get<any>(`${this.basePath}/api/BackupDB`, { headers });

  }
  create_google_drive_DBBackup(): Observable<any> {
    let headers = this.getHeader();

    return this.http.post<any>(`${this.basePath}/api/Google_Drive_Db_BackUp`, { basePath: this.basePath }, { headers });

  }


  logout(): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(`${this.basePath}/api/logout`, { headers });
  }

  // login(data: any) {
  //   let headers = this.getHeader();
  //   return this.http.post<any>(`${this.basePath}/api/login`, data,
  //     {
  //       headers,
  //     });
  // }
  verifyOtp(data: any) {
    let headers = this.getHeader();
    return this.http.post<any>(`${this.basePath}/api/verifyloginotp`, data,
      {
        headers,
      });
  }
  sendOtp(data: any) {
    let headers = this.getHeader();
    return this.http.post<any>(`${this.basePath}/api/login`, data,
      {
        headers,
      });
  }
  addMobileEmail(data: any) {
    let headers = this.getHeader();
    return this.http.post<any>(`${this.basePath}/api/addMobileEmail`, data,
      {
        headers,
      });
  }

  getErrorLogList(from_date: any | 'null', to_date: any | 'null'): Observable<any> {
    let headers = this.getHeader();
    from_date.setHours(0, 0, 0, 0);
    to_date.setHours(23, 59, 59, 59);

    return this.http.get<any>(`${this.basePath}/api/ErrorLog/${encodeURIComponent(from_date)}/${encodeURIComponent(
      to_date
    )}`, { headers });

  }
  //Expense API service
  getExpenseList(): Observable<any> {
    let headers = this.getHeader();

    return this.http
      .get<any>(`${this.basePath}/api/Expense`,
        {
          observe: "response",
          headers,
        }
      )
  }

  addExpense(data: models.TransactionObj | 'null'): Observable<any> {
    let headers = this.getHeader();

    return this.http
      .post<any>(`${this.basePath}/api/Expense`, data,
        {
          headers
        }
      )
  }

  getExpense(id: string | 'null'): Observable<any> {
    let headers = this.getHeader();

    return this.http
      .get<any>(
        `${this.basePath}/api/Expense/${encodeURIComponent(id)}`
        ,
        {
          headers
        }
      )
  }

  deleteExpense(id: string | 'null'): Observable<any> {
    let headers = this.getHeader();

    return this.http
      .delete<any>(
        `${this.basePath}/api/Expense/${encodeURIComponent(id)}`
        ,
        {
          headers
        }
      )
  }

  editExpense(id: string | 'null', data: models.TransactionObj | 'null'): Observable<any> {
    let headers = this.getHeader();
    return this.http
      .put<any>(
        `${this.basePath}/api/Expense/${encodeURIComponent(id)}`, data
        ,
        {
          headers
        }
      )
  }

  // import { Injectable } from "@angular/core";
  // import { Location } from "@angular/common";
  // import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
  // import { Observable, map } from "rxjs";
  // import { BehaviorSubject } from "rxjs";
  // // import "rxjs/add/operator/map";
  // // import "rxjs/add/operator/catch";
  // // import "rxjs/add/operator/switchMap";
  // // import "rxjs/add/observable/throw";
  // import { environment } from "../environments/environment";
  // import { CookieService } from "ngx-cookie-service";
  // import * as models from "./data.modal";
  // import { ConfigService } from "./config.service";
  // @Injectable({
  //   providedIn: 'root'
  // })
  // export class DataService {
  //   basePath: string;
  //   cookieValue: any;
  //   constructor(
  //     private http: HttpClient,
  //     private config: ConfigService,
  //     private cookieService: CookieService
  //   ) {
  //     this.basePath = environment.basePath;
  //     //this.basePath = 'http://localhost:3002/';
  //   }

  //   getWerraVendorHeaders(): HttpHeaders  {
  //     let headers = new HttpHeaders();
  //     headers = headers.set("Accept", "application/json");
  //     headers = headers.set("Content-Type", "application/json");
  //     headers = headers.set("X-Wera-Api-Key", "API_KEY");
  //     return headers;
  //   }
  // getHeader(): HttpHeaders  {
  //   let headers = new HttpHeaders();
  //   this.cookieValue = this.cookieService.get("SESSIONID");
  //   headers = headers.set("Accept", "application/json");
  //   headers = headers.set("Content-Type", "application/json");
  //   headers = headers.set("Authorization", "bearer " + this.cookieValue);
  //   return headers;
  // }
  //Backup Database service
  // createDBBackup(): Observable<any> {
  //   let headers = new HttpHeaders();
  //   headers = headers.set("Accept", "application/zip");
  //   headers = headers.set("Content-Type", "application/zip");
  //   this.cookieValue = this.cookieService.get("SESSIONID");
  //   headers = headers.set("Authorization", "bearer " + this.cookieValue);

  //   return this.http
  //     .request(
  //       "get",
  //       Location.joinWithSlash(`${this.basePath}/api/BackupDB`,
  //       {
  //         responseType: "blob",
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }
  // create_google_drive_DBBackup(): Observable<any> {
  //   let headers = this.getHeader();
  //   return this.http
  //     .request(
  //       "post",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/Google_Drive_Db_BackUp`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //         body: { basePath: this.basePath },
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // get_last_db_backup(): Observable<any> {
  //   let headers = this.getHeader();
  //   return this.http
  //     .request(
  //       "get",
  //       Location.joinWithSlash(`${this.basePath}/api/LastBackup`,
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // // Account Map API service
  getAccountMapList(): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(`${this.basePath}/api/AccountMap`, { headers });

  }
  // Account Map API service
  getEBillAcountMapData(): Observable<any> {
    let headers = this.getHeader();

    return this.http
      .get<any>(
        `${this.basePath}/api/getEBillAcountMapData`
        ,
        {
          headers
        }
      )
  }


  getuserList(): Observable<any> {
    let headers = this.getHeader();
    return this.http
      .get<any>(`${this.basePath}/api/getAllUser`,
        {
          observe: "response",
          headers,
        }
      )
  }

  // Account Map API service
  getBankAccountList(): Observable<any> {
    let headers = this.getHeader();

    return this.http
      .get<any>(`${this.basePath}/api/BankAccountList`,
        {
          observe: "response",
          headers,
        }
      )
  }

  addAccountMap(data: any | 'null'): Observable<any> {
    let headers = this.getHeader();

    return this.http
      .post<any>(`${this.basePath}/api/AccountMap`,
        {
          headers
        }
      )
  }

  getAccountMap(id: string | 'null'): Observable<any> {
    let headers = this.getHeader();

    return this.http
      .get<any>(
        `${this.basePath}/api/AccountMap/${encodeURIComponent(id)}`
        ,
        {
          headers,
        }
      )
  }

  deleteAccountMap(id: string | 'null'): Observable<any> {
    let headers = this.getHeader();

    return this.http
      .delete<any>(
        `${this.basePath}/api/AccountMap/${encodeURIComponent(id)}`
        ,
        {
          headers,
        }
      )
  }

  editAccountMap(id: string | 'null', data: any | 'null'): Observable<any> {
    let headers = this.getHeader();
    return this.http
      .put<any>(
        `${this.basePath}/api/AccountMap/${encodeURIComponent(id)}`
        , data,
        {
          headers
        }
      )
  }

  getSundryLedgerList(filter: string | 'null', inactive: string | 'null'): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(`${this.basePath}/api/CustomerLedger/${encodeURIComponent(
      filter
    )}/${encodeURIComponent(inactive)}`, { headers });
  }

  getCashBankLedgerList(): Observable<any> {
    let headers = this.getHeader();

    return this.http
      .get<any>(`${this.basePath}/api/CashBankLedger`,
        {
          observe: "response",
          headers,
        }
      )
  }
  getExpenseLedgerList(): Observable<any> {
    let headers = this.getHeader();

    return this.http
      .get<any>(`${this.basePath}/api/ExpenseLedger`,
        {
          headers,
        }
      )
  }
  // getLedgerListWithoutCashBank(filter: string | 'null'): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "get",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/LedgerWithoutCashBank/${encodeURIComponent(filter)}`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }


  // getLedger(id: any | 'null'): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "get",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/Ledger/${encodeURIComponent(id)}`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // deleteLedger(id: string | 'null'): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "delete",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/Ledger/${encodeURIComponent(id)}`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }


  // //Category API service
  // getCategoryList(): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "get",
  //       Location.joinWithSlash(`${this.basePath}/api/Category`,
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // addCategory(data: models.CategoryObj | 'null'): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "post",
  //       Location.joinWithSlash(`${this.basePath}/api/Category`,
  //       {
  //         observe: "response",
  //         headers,
  //         body: JSON.stringify(data),
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // getCategory(id: string | 'null'): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "get",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/Category/${encodeURIComponent(id)}`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // deleteCategory(id: string | 'null'): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "delete",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/Category/${encodeURIComponent(id)}`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // editCategory(id: string | 'null', data: models.CategoryObj | 'null'): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "put",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/Category/${encodeURIComponent(id)}`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //         body: JSON.stringify(data),
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // //Items API service
  // getItemList(): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "get",
  //       Location.joinWithSlash(`${this.basePath}/api/Items`,
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // //Custom Items API service
  // getCustomItemList(filter: any): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "get",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/CustomItemList/${encodeURIComponent(filter)}`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // addItem(data:any) {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "post",
  //       Location.joinWithSlash(`${this.basePath}/api/Items`,
  //       {
  //         observe: "response",
  //         headers,
  //         body: data,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // importItems(data:any) {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "post",
  //       Location.joinWithSlash(`${this.basePath}/api/ImportItems`,
  //       {
  //         observe: "response",
  //         headers,
  //         body: data,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }
  // importImages(data:any) {
  //   let headers = new HttpHeaders();
  //   headers = headers.set("Accept", "multipart/form-data");
  //   this.cookieValue = this.cookieService.get("SESSIONID");
  //   headers = headers.set("Authorization", "bearer " + this.cookieValue);

  //   return this.http
  //     .request(
  //       "post",
  //       Location.joinWithSlash(`${this.basePath}/api/ImportImages`,
  //       {
  //         observe: "response",
  //         headers,
  //         body: data,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }
  getTaxslabList(): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(`${this.basePath}/api/Taxslab`, { headers });
  }

  getItemByBarcode(barcode: string | 'null'): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(`${this.basePath}/api/ItemsByBarcode/${encodeURIComponent(barcode)}`, { headers });

  }





  editItem(id: string | 'null', data: models.ItemObj | 'null'): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(`${this.basePath}/api/Items/${encodeURIComponent(id)}`, { headers });

  }


  // //Contracts API service
  getContractList(): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(`${this.basePath}/api/Contracts`, { headers });

  }

  // addContract(data: models.ContractObj | 'null'): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "post",
  //       Location.joinWithSlash(`${this.basePath}/api/Contracts`,
  //       {
  //         observe: "response",
  //         headers,
  //         body: JSON.stringify(data),
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // getContract(id: string | 'null'): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "get",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/Contracts/${encodeURIComponent(id)}`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // deleteContract(id: string | 'null'): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "delete",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/Contracts/${encodeURIComponent(id)}`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //       switch (response.status) {
  //       }
  //     })
  //   )
  // }

  // editContract(id: string | 'null', data: models.ContractObj | 'null'): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "put",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/Contracts/${encodeURIComponent(id)}`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //         body: JSON.stringify(data),
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }
  // // PurchaseOrder API service
  // getPurchaseOrderList(): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "get",
  //       Location.joinWithSlash(`${this.basePath}/api/PurchaseOrder`,
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // addPurchaseOrder(data: models.TransactionObj | 'null'): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "post",
  //       Location.joinWithSlash(`${this.basePath}/api/PurchaseOrder`,
  //       {
  //         observe: "response",
  //         headers,
  //         body: JSON.stringify(data),
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // getPurchaseOrder(id: string | 'null'): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "get",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/PurchaseOrder/${encodeURIComponent(id)}`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // deletePurchaseOrder(id: string | 'null'): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "delete",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/PurchaseOrder/${encodeURIComponent(id)}`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //       switch (response.status) {
  //       }
  //     })
  //   )
  // }

  // editPurchaseOrder(id: string | 'null', data: models.TransactionObj | 'null'): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "put",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/PurchaseOrder/${encodeURIComponent(id)}`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //         body: JSON.stringify(data),
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }
  // //Sales Order API service
  // getSalesOrderList(): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "get",
  //       Location.joinWithSlash(`${this.basePath}/api/SalesOrder`,
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // addSalesOrder(data: models.TransactionObj | 'null'): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "post",
  //       Location.joinWithSlash(`${this.basePath}/api/SalesOrder`,
  //       {
  //         observe: "response",
  //         headers,
  //         body: JSON.stringify(data),
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // getSalesOrder(id: string | 'null'): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "get",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/SalesOrder/${encodeURIComponent(id)}`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // deleteSalesOrder(id: string | 'null', reason: any): Observable<any> {
  //   let headers = this.getHeader();
  //   return this.http
  //     .request(
  //       "delete",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/SalesOrder/${encodeURIComponent(id)}`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //         body: JSON.stringify(reason),
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // editSalesOrder(id: string | 'null', data: any): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "put",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/SalesOrder/${encodeURIComponent(id)}`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //         body: JSON.stringify(data),
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }
  // //Purchase API service
  // getPurchaseList(): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "get",
  //       Location.joinWithSlash(`${this.basePath}/api/Purchase`,
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // addPurchase(data: models.TransactionObj | 'null'): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "post",
  //       Location.joinWithSlash(`${this.basePath}/api/Purchase`,
  //       {
  //         observe: "response",
  //         headers,
  //         body: JSON.stringify(data),
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // getPurchase(id: string | 'null'): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "get",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/Purchase/${encodeURIComponent(id)}`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // deletePurchase(id: string | 'null'): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "delete",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/Purchase/${encodeURIComponent(id)}`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //       switch (response.status) {
  //       }
  //     })
  //   )
  // }

  // editPurchase(id: string | 'null', data: models.TransactionObj | 'null'): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "put",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/Purchase/${encodeURIComponent(id)}`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //         body: JSON.stringify(data),
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }
  // //PurchaseReturn API service
  // getPurchaseReturnList(): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "get",
  //       Location.joinWithSlash(`${this.basePath}/api/PurchaseReturn`,
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // addPurchaseReturn(data: models.TransactionObj | 'null'): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "post",
  //       Location.joinWithSlash(`${this.basePath}/api/PurchaseReturn`,
  //       {
  //         observe: "response",
  //         headers,
  //         body: JSON.stringify(data),
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // getPurchaseReturn(id: string | 'null'): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "get",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/PurchaseReturn/${encodeURIComponent(id)}`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // deletePurchaseReturn(id: string | 'null'): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "delete",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/PurchaseReturn/${encodeURIComponent(id)}`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //       switch (response.status) {
  //       }
  //     })
  //   )
  // }

  // editPurchaseReturn(id: string | 'null', data: models.TransactionObj | 'null'): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "put",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/PurchaseReturn/${encodeURIComponent(id)}`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //         body: JSON.stringify(data),
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }
  //GRN API service
  getGrnList(): Observable<any> {
    let headers = this.getHeader();

    return this.http
      .get<any>(`${this.basePath}/api/GRN`, {
        headers,
      })

  }

  addGrn(data: models.TransactionObj | 'null'): Observable<any> {
    let headers = this.getHeader();

    return this.http
      .post<any>(`${this.basePath}/api/GRN`, data, {
        headers
      })

  }

  getGrn(id: string | 'null'): Observable<any> {
    let headers = this.getHeader();

    return this.http
      .get<any>(
        `${this.basePath}/api/GRN/${encodeURIComponent(id)}`
        ,
        {
          headers,
        }
      )
  }

  deleteGrn(id: string | 'null'): Observable<any> {
    let headers = this.getHeader();

    return this.http
      .delete<any>(
        `${this.basePath}/api/GRN/${encodeURIComponent(id)}`
        ,
        {
          headers,
        }

      )
  }

  editGrn(id: string | 'null', data: models.TransactionObj | 'null'): Observable<any> {
    let headers = this.getHeader();

    return this.http
      .put<any>(
        `${this.basePath}/api/GRN/${encodeURIComponent(id)}`
        , data,
        {
          headers
        }
      )
  }
  //DeliveryOrder API service
  getDeliveryOrderList(): Observable<any> {
    let headers = this.getHeader();

    return this.http
      .get<any>(`${this.basePath}/api/DeliveryOrder`,
        {
          observe: "response",
          headers,
        }
      )
  }

  addDeliveryOrder(data: models.TransactionObj | 'null'): Observable<any> {
    let headers = this.getHeader();

    return this.http
      .post<any>(`${this.basePath}/api/DeliveryOrder`,
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
  }

  getDeliveryOrder(id: string | 'null'): Observable<any> {
    let headers = this.getHeader();

    return this.http
      .get<any>(
        `${this.basePath}/api/DeliveryOrder/${encodeURIComponent(id)}`
        ,
        {
          headers
        }
      )
  }

  deleteDeliveryOrder(id: string | 'null'): Observable<any> {
    let headers = this.getHeader();

    return this.http
      .delete<any>(
        `${this.basePath}/api/DeliveryOrder/${encodeURIComponent(id)}`
        ,
        {
          headers,
        }

      )
  }

  editDeliveryOrder(id: string | 'null', data: models.TransactionObj | 'null'): Observable<any> {
    let headers = this.getHeader();

    return this.http
      .put<any>(
        `${this.basePath}/api/DeliveryOrder/${encodeURIComponent(id)}`
        , data,
        {
          headers
        }
      )
  }
  // // Invoice API service
  getInvoiceList(): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(`${this.basePath}/api/Invoice`, { headers });
  }

  getUnpaidInvoice(table_id: any): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(`${this.basePath}/api/getUnpaidInvoice/${encodeURIComponent(table_id)}`, { headers });

  }

  addInvoice(data: models.TransactionObj | 'null'): Observable<any> {
    let headers = this.getHeader();

    return this.http.post<any>(`${this.basePath}/api/Invoice`, data, { headers });

  }
  addInvoiceAndEmail(data: models.TransactionObj | 'null'): Observable<any> {
    let headers = this.getHeader();

    return this.http
      .post<any>(`${this.basePath}/api/InvoiceAndEmail`, data, { headers });

  }
  getInvoice(id: string | 'null'): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(`${this.basePath}/api/Invoice/${encodeURIComponent(id)}`, { headers });

  }

  deleteInvoice(id: string | 'null'): Observable<any> {
    let headers = this.getHeader();

    return this.http.delete<any>(`${this.basePath}/api/Invoice/${encodeURIComponent(id)}`, { headers });

  }

  editInvoice(id: string | 'null', data: models.TransactionObj | 'null'): Observable<any> {
    let headers = this.getHeader();

    return this.http.put<any>(`${this.basePath}/api/Invoice/${encodeURIComponent(id)}`, data, { headers });

  }
  //Get Voucher Type List
  getVoucherTypeList(): Observable<any> {
    let headers = this.getHeader();

    return this.http
      .get<any>(`${this.basePath}/api/VoucherType`,
        {
          observe: "response",
          headers,
        }
      )
  }
  //Credit Note API Service
  getCreditNoteList(): Observable<any> {
    let headers = this.getHeader();

    return this.http
      .get<any>(`${this.basePath}/api/CreditNote`,
        {
          observe: "response",
          headers,
        }
      )
  }

  addCreditNote(data: models.TransactionObj | 'null'): Observable<any> {
    let headers = this.getHeader();

    return this.http
      .post<any>(`${this.basePath}/api/CreditNote`, data,
        {
          headers
        }
      )
  }

  getCreditNote(id: string | 'null'): Observable<any> {
    let headers = this.getHeader();

    return this.http
      .get<any>(
        `${this.basePath}/api/CreditNote/${encodeURIComponent(id)}`
        ,
        {
          headers
        }
      )
  }

  deleteCreditNote(id: string | 'null'): Observable<any> {
    let headers = this.getHeader();

    return this.http
      .delete(
        `${this.basePath}/api/CreditNote/${encodeURIComponent(id)}`
        ,
        {
          observe: "response",
          headers,
        }

      )
  }

  editCreditNote(id: string | 'null', data: models.TransactionObj | 'null'): Observable<any> {
    let headers = this.getHeader();

    return this.http
      .put<any>(
        `${this.basePath}/api/CreditNote/${encodeURIComponent(id)}`
        , data,
        {
          headers
        }
      )
  }
  // // InvoiceReturn API service
  getInvoiceReturnList(): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(`${this.basePath}/api/InvoiceReturn`, { headers });

  }

  addInvoiceReturn(data: models.TransactionObj | 'null'): Observable<any> {
    let headers = this.getHeader();

    return this.http.post<any>(`${this.basePath}/api/InvoiceReturn`, data, { headers });

  }

  getInvoiceReturn(id: string | 'null'): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(`${this.basePath}/api/InvoiceReturn/${encodeURIComponent(id)}`, { headers });
  }

  deleteInvoiceReturn(id: string | 'null'): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(`${this.basePath}/api/InvoiceReturn/${encodeURIComponent(id)}`, { headers });

  }

  editInvoiceReturn(id: string | 'null', data: models.TransactionObj | 'null'): Observable<any> {
    let headers = this.getHeader();

    return this.http.post<any>(`${this.basePath}/api/InvoiceReturn/${encodeURIComponent(id)}`, { headers });

  }
  getVoucherList(
    from_date: any | 'null',
    to_date: any | 'null',
    type: string | 'null'
  ) {
    let headers = this.getHeader();
    // Ensure from_date and to_date are Date objects
    if (from_date && typeof from_date !== 'object') {
      from_date = new Date(from_date); // Convert from string to Date
    }
    if (to_date && typeof to_date !== 'object') {
      to_date = new Date(to_date); // Convert from string to Date
    }

    from_date.setHours(0, 0, 0, 1);
    to_date.setHours(23, 59, 59, 59);
    return this.http
      .get<any>(
        `${this.basePath}/api/AccountVoucher/${encodeURIComponent(
          from_date
        )}/${encodeURIComponent(to_date)}/${encodeURIComponent(type)}`
        ,
        {
          headers,
        }
      )
  }
  // getAllVoucherList(from_date: string | 'null', to_date: string | 'null', type: string | 'null'): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http.request('get', Location.joinWithSlash(`${this.basePath}/api/AllVoucher/${encodeURIComponent(from_date)}/${encodeURIComponent(to_date)}/${encodeURIComponent(type)}`, {
  //     observe: 'response',
  //     headers
  //   })
  //     .map(response => {
  //       switch (response.status) {
  //         case 200: {
  //           return response.body;
  //         }
  //       }
  //     })
  // }

  getAllVoucherList(
    from_date: any | 'null',
    to_date: any | 'null',
    type: string | 'null',
    user_id: any
  ) {
    // Ensure from_date and to_date are Date objects
    if (from_date && typeof from_date !== 'object') {
      from_date = new Date(from_date); // Convert from string to Date
    }
    if (to_date && typeof to_date !== 'object') {
      to_date = new Date(to_date); // Convert from string to Date
    }

    from_date.setHours(0, 0, 0, 1);
    to_date.setHours(23, 59, 59, 59);
    let headers = this.getHeader();
    return this.http
      .get<any>(
        `${this.basePath}/api/AllVoucher/${encodeURIComponent(
          from_date
        )}/${encodeURIComponent(to_date)}/${encodeURIComponent(
          type
        )}/${encodeURIComponent(user_id)}`
        ,
        {
          headers,
        }
      )
  }

  getTrialBalance(from_date: any | 'null', to_date: any | 'null'): Observable<any> {
    let headers = this.getHeader();
    from_date.setHours(0, 0, 0, 0);
    to_date.setHours(23, 59, 59, 59);
    return this.http
      .get<any>(
        `${this.basePath}/api/TrialBalance/${encodeURIComponent(
          from_date
        )}/${encodeURIComponent(to_date)}`
        ,
        {
          headers,
        }
      )
  }

  getMonthlyTrialBalance(
    from_date: any | 'null',
    to_date: any | 'null',
    ledger: string | 'null'
  ) {
    let headers = this.getHeader();
    from_date.setHours(0, 0, 0, 0);
    to_date.setHours(23, 59, 59, 59);
    return this.http
      .get<any>(
        `${this.basePath}/api/MonthlyTrialBalance/${encodeURIComponent(
          from_date
        )}/${encodeURIComponent(to_date)}/${encodeURIComponent(ledger)}`
        ,
        {
          headers,
        }
      )
  }
  getCustomerOutstanding(): Observable<any> {
    let headers = this.getHeader();

    return this.http
      .get<any>(`${this.basePath}/api/CustomerOutstanding`,
        {
          headers,
        }
      )
  }
  getSupplierOutstanding(): Observable<any> {
    let headers = this.getHeader();

    return this.http
      .get<any>(`${this.basePath}/api/SupplierOutstanding`,
        {
          headers,
        }
      )
  }
  //Get category Stock
  getCategoryStock(
    from_date: any | 'null',
    to_date: any | 'null',
    type: string | 'null'
  ) {
    let headers = this.getHeader();
    from_date.setHours(0, 0, 0, 0);
    to_date.setHours(23, 59, 59, 59);
    return this.http
      .get<any>(
        `${this.basePath}/api/CategoryStock/${encodeURIComponent(
          from_date
        )}/${encodeURIComponent(to_date)}/${encodeURIComponent(type)}`
        ,
        {
          headers,
        }
      )
  }

  //Get no of purchase rate
  noOfPurchaseRate(
    from_date: any | 'null',
    to_date: any | 'null',
    item: string | 'null'
  ) {
    let headers = this.getHeader();
    from_date.setHours(0, 0, 0, 0);
    to_date.setHours(23, 59, 59, 59);
    return this.http
      .get<any>(
        `${this.basePath}/api/getNoOfPurchaseRate/${encodeURIComponent(
          from_date
        )}/${encodeURIComponent(to_date)}/${encodeURIComponent(item)}`
        ,
        {
          headers,
        }
      )
  }
  //Get Purchase Wise Item Stock
  getPurchaseWiseItemStock(
    from_date: any | 'null',
    to_date: any | 'null',
    item: string | 'null'
  ) {
    let headers = this.getHeader();
    from_date.setHours(0, 0, 0, 0);
    to_date.setHours(23, 59, 59, 59);
    return this.http
      .get<any>(
        `${this.basePath}/api/getPurchaseWiseItemStock/${encodeURIComponent(
          from_date
        )}/${encodeURIComponent(to_date)}/${encodeURIComponent(item)}`
        ,
        {
          headers,
        }
      )
  }

  //Get Tax Summary
  getTaxSummary(
    from_date: any | 'null',
    to_date: any | 'null',
    type_id: string | 'null',
    show_for: string | 'null',
    summaryFlag: any
  ) {
    let headers = this.getHeader();
    from_date.setHours(0, 0, 0, 0);
    to_date.setHours(23, 59, 59, 59);
    return this.http
      .get<any>(
        `${this.basePath}/api/TaxSummary/${encodeURIComponent(
          from_date
        )}/${encodeURIComponent(to_date)}/${encodeURIComponent(
          type_id
        )}/${encodeURIComponent(show_for)}/${encodeURIComponent(summaryFlag)}`
        ,
        {
          headers,
        }
      )
  }
  //Balance Sheet and Profit and Loss

  getAccVoucherList(
    from_date: any | 'null',
    to_date: any | 'null',
    ledger: string | 'null'
  ) {
    let headers = this.getHeader();
    from_date.setHours(0, 0, 0, 0);
    to_date.setHours(23, 59, 59, 59);
    return this.http
      .get<any>(
        `${this.basePath}/api/AccVoucher/${encodeURIComponent(
          from_date
        )}/${encodeURIComponent(to_date)}/${encodeURIComponent(ledger)}`
        ,
        {
          headers,
        }
      )
  }




  downloadPdf(data: models.TransactionObj | 'null'): Observable<Blob> {
    let headers = new HttpHeaders();

    headers = headers.set("Accept", "application/pdf");
    headers = headers.set("Content-Type", "application/json");
    this.cookieValue = this.cookieService.get("SESSIONID");
    // headers = headers.set("Authorization", "bearer " + this.cookieValue);
    headers = headers.set("Authorization", "Bearer " + this.cookieValue)

    return this.http.post<any>(`${this.basePath}/api/PdfDownload`, data, {
      headers,
      responseType: "blob" as 'json',
    });

  }

  downloadPdfByTransId(id: any): Observable<Blob> {
    let headers = new HttpHeaders();

    headers = headers.set("Accept", "application/pdf");
    headers = headers.set("Content-Type", "application/json");
    this.cookieValue = this.cookieService.get("SESSIONID");
    // headers = headers.set("Authorization", "bearer " + this.cookieValue);
    headers = headers.set("Authorization", "Bearer " + this.cookieValue)

    return this.http
      .get<any>(
        `${this.basePath}/api/printApprovedTransaction/${encodeURIComponent(id)}`
        ,
        {
          headers,
          responseType: "blob" as 'json',
        }
      )

  }
  printTablesQrCodes(): Observable<Blob> {
    let headers = new HttpHeaders();

    headers = headers.set("Accept", "application/pdf");
    headers = headers.set("Content-Type", "application/json");
    this.cookieValue = this.cookieService.get("SESSIONID");
    // headers = headers.set("Authorization", "bearer " + this.cookieValue);
    headers = headers.set("Authorization", "Bearer " + this.cookieValue)

    return this.http
      .get<any>(`${this.basePath}/api/PrintTablesQRcode`,
        {
          headers,
          responseType: "blob" as "json",
        }
      )

  }

  // //Payment API service
  // getPaymentList(): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "get",
  //       Location.joinWithSlash(`${this.basePath}/api/Payment`,
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // addPayment(data: models.TransactionObj | 'null'): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "post",
  //       Location.joinWithSlash(`${this.basePath}/api/Payment`,
  //       {
  //         observe: "response",
  //         headers,
  //         body: JSON.stringify(data),
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // getPayment(id: string | 'null'): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "get",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/Payment/${encodeURIComponent(id)}`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // deletePayment(id: string | 'null'): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "delete",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/Payment/${encodeURIComponent(id)}`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //       switch (response.status) {
  //       }
  //     })
  //   )
  // }

  // editPayment(id: string | 'null', data: models.TransactionObj | 'null'): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "put",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/Payment/${encodeURIComponent(id)}`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //         body: JSON.stringify(data),
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }
  // //Receipt API service
  // getReceiptList(): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "get",
  //       Location.joinWithSlash(`${this.basePath}/api/Receipt`,
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // addReceipt(data: models.TransactionObj | 'null'): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "post",
  //       Location.joinWithSlash(`${this.basePath}/api/Receipt`,
  //       {
  //         observe: "response",
  //         headers,
  //         body: JSON.stringify(data),
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // getReceipt(id: string | 'null'): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "get",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/Receipt/${encodeURIComponent(id)}`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // deleteReceipt(id: string | 'null'): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "delete",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/Receipt/${encodeURIComponent(id)}`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //       switch (response.status) {
  //       }
  //     })
  //   )
  // }

  // editReceipt(id: string | 'null', data: models.TransactionObj | 'null'): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "put",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/Receipt/${encodeURIComponent(id)}`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //         body: JSON.stringify(data),
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }
  // //Expense API service
  // getExpenseList(): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "get",
  //       Location.joinWithSlash(`${this.basePath}/api/Expense`,
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // addExpense(data: models.TransactionObj | 'null'): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "post",
  //       Location.joinWithSlash(`${this.basePath}/api/Expense`,
  //       {
  //         observe: "response",
  //         headers,
  //         body: JSON.stringify(data),
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // getExpense(id: string | 'null'): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "get",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/Expense/${encodeURIComponent(id)}`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // deleteExpense(id: string | 'null'): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "delete",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/Expense/${encodeURIComponent(id)}`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //       switch (response.status) {
  //       }
  //     })
  //   )
  // }

  // editExpense(id: string | 'null', data: models.TransactionObj | 'null'): Observable<any> {
  //   let headers = this.getHeader();
  //   return this.http
  //     .request(
  //       "put",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/Expense/${encodeURIComponent(id)}`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //         body: JSON.stringify(data),
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }




  // login(data: any): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "post",
  //       Location.joinWithSlash(`${this.basePath}/api/login`,
  //       {
  //         headers: headers,
  //         observe: "response",
  //         body: JSON.stringify(data),
  //       }
  //     )
  //     .pipe(
  //       map((response: HttpResponse<any>) => {
  //         if (response.status === 200) {
  //           return response.body; // Cast the response body as LoginResponse
  //         } else {
  //           // Handle non-200 status codes here
  //           // Throw an error or return a default value based on your API's behavior
  //           // return throwError(() => new Error(`Unexpected status code: ${response.status}`));
  //         }
  //       }),
  //       // catchError(this.handleError)
  //     );
  // }

  // logout(): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "get",
  //       Location.joinWithSlash(`${this.basePath}/api/logout`,
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }
  // checkTenentDB(): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "get",
  //       Location.joinWithSlash(`${this.basePath}/api/CheckTenent`,
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }
  //Tenent Login
  tenentLogin(data: any): Observable<any> {
    const headers = this.getHeader();

    return this.http.post<any>(`${this.basePath}/api/tenentLogin`, data,
      {
        headers
      }
    )

  }
  tenentLogout(): Observable<any> {
    let headers = this.getHeader();

    return this.http
      .get<any>(`${this.basePath}/api/tenentLogout`,
        {
          headers
        }
      )
  }

  //Update Rc_date of account voucher
  editRcDate(data: models.MenuPermissionObj | 'null'): Observable<any> {
    let headers = this.getHeader();

    return this.http
      .post<any>(`${this.basePath}/api/RcDate`, data,
        {
          headers
        }
      )
  }
  //Tenent API Service
  getTenentList(): Observable<any> {
    let headers = this.getHeader();
    headers = headers.set("Authorization", "Bearer " + localStorage.getItem("token"));
    console.log("headers", headers) 

    return this.http
      .get<any>(`${this.basePath}/api/Tenent`,
        {
          headers
        }
      )
  }

  addTenent(data: models.TenentObj | 'null'): Observable<any> {
    let headers = this.getHeader();

    return this.http
      .post<any>(`${this.basePath}/api/Tenent`, data,
        {
          headers
        }
      )
  }

  getTenent(id: string | 'null'): Observable<any> {
    let headers = this.getHeader();
    return this.http
      .get<any>(
        `${this.basePath}/api/Tenent/${encodeURIComponent(id)}`
        ,
        {
          headers,
        }
      )
  }

  deleteTenent(id: string | 'null'): Observable<any> {
    let headers = this.getHeader();

    return this.http
      .delete<any>(
        `${this.basePath}/api/Tenent/${encodeURIComponent(id)}`
        ,
        {
          headers,
        }
      )
  }

  editTenent(id: string | 'null', data: models.TenentObj | 'null'): Observable<any> {
    let headers = this.getHeader();

    return this.http
      .put<any>(
        `${this.basePath}/api/Tenent/${encodeURIComponent(id)}`
        , data,
        {
          headers
        }
      )
  }

  // //Company Information API service
  getCompanyInfo(): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(`${this.basePath}/api/CompanyInfo`, { headers });

  }
  // //reset sms setting
  // resetSmsSetting(): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "get",
  //       Location.joinWithSlash(`${this.basePath}/api/resetSmsSetting`,
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }
  // editCompanyInfo(id: string | 'null', data: models.TenentObj | 'null'): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "put",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/CompanyInfo/${encodeURIComponent(id)}`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //         body: JSON.stringify(data),
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // // Dashboard
  // getTransCount(
  //   from_date: any | 'null',
  //   to_date: any | 'null',
  //   type: string | 'null'
  // ) {
  //   let headers = this.getHeader();
  //   from_date.setHours(0, 0, 0, 0);
  //   to_date.setHours(23, 59, 59, 59);
  //   return this.http
  //     .request(
  //       "get",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/vouchercount/${encodeURIComponent(
  //           from_date
  //         )}/${encodeURIComponent(to_date)}/${encodeURIComponent(type)}`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // getSalesInv(
  //   from_date: string | 'null',
  //   to_date: string | 'null',
  //   type: string | 'null'
  // ) {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "get",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/salesinv/${encodeURIComponent(from_date)}/${encodeURIComponent(
  //           to_date
  //         )}/${encodeURIComponent(type)}`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // getInvoiceCountTrans(from_date: any | 'null', to_date: any | 'null'): Observable<any> {
  //   let headers = this.getHeader();
  //   from_date.setHours(0, 0, 0, 0);
  //   to_date.setHours(23, 59, 59, 59);
  //   return this.http
  //     .request(
  //       "get",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/invoiceGraph/${encodeURIComponent(
  //           from_date
  //         )}/${encodeURIComponent(to_date)}`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // //Company Terms and Conditions API service
  // getTermsConditionsList(): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "get",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/CompanyTermsConditions`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // addTermCondition(data: any | 'null'): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "post",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/CompanyTermsConditions`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //         body: JSON.stringify(data),
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // getTermCondition(id: string | 'null'): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "get",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/CompanyTermsConditions/${encodeURIComponent(id)}`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // deleteTermCondition(id: string | 'null'): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "delete",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/CompanyTermsConditions/${encodeURIComponent(id)}`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // editTermCondition(id: string | 'null', data: any | 'null'): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "put",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/CompanyTermsConditions/${encodeURIComponent(id)}`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //         body: JSON.stringify(data),
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  sgetGstState(filter: string | 'null', inactive: string | 'null'): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(`${this.basePath}/api/sgstState/${encodeURIComponent(filter)}/${encodeURIComponent(
      inactive
    )}`, { headers });
  }

  // //get gst state code
  getGstState(): Observable<any> {
    let headers = this.getHeader();
    return this.http.get<any>(`${this.basePath}/api/tntgstState`, { headers });
  }

  //get gst state code
  getTntGstState(): Observable<any> {
    let headers = this.getHeader();
    return this.http
      .get<any>(`${this.basePath}/api/tntgstState`,
        {
          headers,
        }
      )
  }

  //get update logs list
  getUpdateLogsList(): Observable<any> {
    let headers = this.getHeader();

    return this.http
      .get<any>(`${this.basePath}/api/UpdateLogs`,
        {
          headers
        }
      )
  }
  //send new password to owner
  mailPassToOwner(): Observable<any> {
    let headers = this.getHeader();
    return this.http
      .get<any>(`${this.basePath}/api/mailPassToOwner`,
        {
          headers
        }
      )
  }
  //Due receipt API service
  getDueReceiptList(id: string | 'null'): Observable<any> {
    let headers = this.getHeader();
    return this.http
      .get<any>(
        `${this.basePath}/api/DueReceipt/${encodeURIComponent(id)}`
        ,
        {
          headers
        }
      )
  }
  //Due Payment API service
  getDuePaymentList(id: string | 'null'): Observable<any> {
    let headers = this.getHeader();
    return this.http
      .get<any>(
        `${this.basePath}/api/DuePayment/${encodeURIComponent(id)}`
        ,
        {
          headers
        }
      )
  }
  // //Convert Sales Order to Delivery Order API service
  convertSOtoDO(id: string | 'null'): Observable<any> {
    let headers = this.getHeader();
    return this.http
      .get<any>(
        `${this.basePath}/api/SOtoDO/${encodeURIComponent(id)}`
        ,
        {
          headers,
        }
      )
  }
  //Tables QR codes
  getTablesQRcodeList(): Observable<any> {
    let headers = this.getHeader();
    return this.http
      .get<any>(`${this.basePath}/api/TablesQRcode`,
        {
          headers,
        }
      )
  }

  // printTablesQrCodes(): Observable<any> {
  //   let headers = new HttpHeaders();

  //   headers = headers.set("Accept", "application/pdf");
  //   headers = headers.set("Content-Type", "application/json");
  //   this.cookieValue = this.cookieService.get("SESSIONID");
  //   headers = headers.set("Authorization", "bearer " + this.cookieValue);

  //   return this.http
  //     .request(
  //       "get",
  //       Location.joinWithSlash(`${this.basePath}/api/PrintTablesQRcode`,
  //       {
  //         observe: "response",
  //         headers,
  //         responseType: "blob",
  //       }
  //     )
  //     .pipe(
  //       map((response: HttpResponse<any>) => {
  //       switch (response.status) {
  //         case 200: {
  //           return response.body;
  //         }
  //       }
  //     })
  //   )
  // }

  //Table Group API service
  getTableGroupList(): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(`${this.basePath}/api/TableGroup`, { headers });

  }

  addTableGroup(data: models.CategoryObj | 'null'): Observable<any> {
    let headers = this.getHeader();

    return this.http.post<any>(`${this.basePath}/api/TableGroup`, data, { headers });


  }

  getTableGroup(id: string | 'null'): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(`${this.basePath}/api/TableGroup/${encodeURIComponent(id)}`, { headers });

  }

  getTableByTransId(id: string | 'null'): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(`${this.basePath}/api/TableByTransId/${encodeURIComponent(id)}`, { headers });

  }

  deleteTableGroup(id: string | 'null'): Observable<any> {
    let headers = this.getHeader();

    return this.http.delete<any>(`${this.basePath}/api/TableGroup/${encodeURIComponent(id)}`, { headers });

  }

  editTableGroup(id: string | 'null', data: models.CategoryObj | 'null'): Observable<any> {
    let headers = this.getHeader();

    return this.http.put<any>(`${this.basePath}/api/TableGroup/${encodeURIComponent(id)}`, data, { headers });

  }
  //get so list which is not converted to invoice
  getSONotToInvoice(ledger_id: string | 'null', table_id: string | 'null'): Observable<any> {
    let headers = this.getHeader();

    return this.http
      .get<any>(
        `${this.basePath}/api/SOnotInvoiced/${encodeURIComponent(
          ledger_id
        )}/${encodeURIComponent(table_id)}`
        ,
        {
          headers,
        }
      )
  }
  getSONotApproved(): Observable<any> {
    let headers = this.getHeader();

    return this.http
      .get<any>(
        `${this.basePath}/api/SOnotApprovedWithItems`
        ,
        {
          observe: "response",
          headers,
        }
      )
  }
  getSONotFinished(): Observable<any> {
    let headers = this.getHeader();

    return this.http
      .get<any>(
        `${this.basePath}/api/SOnotFinishedWithItems`
        ,
        {
          headers
        }
      )
  }

  approveKot(id: any): Observable<any> {
    let headers = this.getHeader();
    return this.http
      .get<any>(
        `${this.basePath}/api/ApproveKot/${encodeURIComponent(id)}`
        ,
        {
          headers
        }
      )
  }
  // markProcessingKot(id: any): Observable<any> {
  //   let headers = this.getHeader();
  //   return this.http.request('get', Location.joinWithSlash(`${this.basePath}/api/ProcessingKot/${encodeURIComponent(id)}`, {
  //     observe: 'response',
  //     headers
  //   })
  //     .map(response => {
  //       switch (response.status) {
  //         case 200: {
  //           return response.body;
  //         }
  //       }
  //     })
  // }

  markProcessingKot(data: any) {
    let headers = this.getHeader();
    return this.http
      .post<any>(`${this.basePath}/api/ProcessingKot`, data,
        {
          headers
        }
      )


  }

  markOrderPickedUp(transaction_id: any): Observable<any> {
    const headers = this.getHeader();
    const requestBody = { transactionId: transaction_id };
    return this.http
      .post<any>(`${this.basePath}/api/weraOrderPickedUp`, requestBody,
        {
          headers
        }
      )
  }
  weraFoodReady(transaction_id: any): Observable<any> {
    const headers = this.getHeader();
    const requestBody = { transactionId: transaction_id };
    return this.http
      .post<any>(`${this.basePath}/api/foodReadyWeraOrder`, requestBody,
        {
          headers
        }
      )
  }
  markFinishedKot(id: any): Observable<any> {
    let headers = this.getHeader();
    return this.http
      .get<any>(
        `${this.basePath}/api/FinishedKot/${encodeURIComponent(id)}`
        ,
        {
          headers
        }
      )
  }

  //get so list which is converted to invoice
  getSOInvoiceList(id: any): Observable<any> {
    let headers = this.getHeader();
    return this.http
      .get<any>(
        `${this.basePath}/api/SOInvoiced/${encodeURIComponent(id)}`
        ,
        {
          headers
        }
      )
  }
  // //get multiple sales order items list
  getMultipleSOItems(so_id_list: string | 'null', ledger_id: any): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(`${this.basePath}/api/MultipleSOItems/${encodeURIComponent(
      so_id_list
    )}/${encodeURIComponent(ledger_id)}`, { headers });
  }
  //get do list which is not converted to invoice
  getDONotToInvoice(ledger_id: string | 'null', table_id: string | 'null'): Observable<any> {
    let headers = this.getHeader();

    return this.http
      .get<any>(
        `${this.basePath}/api/DOnotInvoiced/${encodeURIComponent(
          ledger_id
        )}/${encodeURIComponent(table_id)}`
        ,
        {
          headers
        }
      )
  }
  //get do list which is converted to invoice
  getDOInvoiceList(id: any): Observable<any> {
    let headers = this.getHeader();
    return this.http
      .get<any>(
        `${this.basePath}/api/DOInvoiced/${encodeURIComponent(id)}`
        ,
        {
          headers
        }
      )
  }
  // //get multiple delivery order items list
  getMultipleDOItems(so_id_list: string | 'null', ledger_id: any): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(`${this.basePath}/api/MultipleDOItems/${encodeURIComponent(
      so_id_list
    )}/${encodeURIComponent(ledger_id)}`, { headers });

  }

  //Templates API Service
  getTemplateListName(id: string | 'null'): Observable<any> {
    let headers = this.getHeader();

    return this.http
      .get<any>(
        `${this.basePath}/api/template/${encodeURIComponent(id)}`
        ,
        {
          headers
        }
      )
  }
  // //Quotation API Service
  // addQuotation(data: any | 'null'): Observable<any> {
  //   let headers = this.getHeader();
  //   return this.http
  //     .request(
  //       "post",
  //       Location.joinWithSlash(`${this.basePath}api/quotation`,
  //       {
  //         observe: "response",
  //         headers,
  //         body: JSON.stringify(data),
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }
  // getQuotationList(): Observable<any> {
  //   let headers = this.getHeader();
  //   return this.http
  //     .request(
  //       "get",
  //       Location.joinWithSlash(`${this.basePath}/api/quotation`,
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // getquoationDetailsList(from_date: any | 'null', to_date: any | 'null'): Observable<any> {
  //   let headers = this.getHeader();
  //   from_date.setHours(0, 0, 0, 0);
  //   to_date.setHours(23, 59, 59, 59);
  //   return this.http
  //     .request(
  //       "get",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/quotation/${encodeURIComponent(from_date)}/${encodeURIComponent(
  //           to_date
  //         )}`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // // get Quotation
  // getQuotation(id: string | 'null'): Observable<any> {
  //   let headers = this.getHeader();
  //   return this.http
  //     .request(
  //       "get",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/quotation/${encodeURIComponent(id)}`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }
  // // Edit Quotation
  // editQuotation(id: string | 'null', data: models.TransactionObj | 'null'): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "put",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/quotation/${encodeURIComponent(id)}`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //         body: JSON.stringify(data),
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // // delete Quotation
  // deleteQuotation(id: string | 'null'): Observable<any> {
  //   let headers = this.getHeader();
  //   return this.http
  //     .request(
  //       "delete",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/quotation/${encodeURIComponent(id)}`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //       switch (response.status) {
  //       }
  //     })
  //   )
  // }
  // editQuotationTerms(id: string | 'null', data: any | 'null'): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "put",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/companyQuotationTerms/${encodeURIComponent(id)}`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //         body: JSON.stringify(data),
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // quotationToSO(data: any | 'null'): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "post",
  //       Location.joinWithSlash(`${this.basePath}/api/quotation_to_so`,
  //       {
  //         observe: "response",
  //         headers,
  //         body: JSON.stringify(data),
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // quotatiobTOInvoice(data: any | 'null'): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "post",
  //       Location.joinWithSlash(`${this.basePath}/api/quotation_to_invoice`,
  //       {
  //         observe: "response",
  //         headers,
  //         body: JSON.stringify(data),
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }
  // PoToPurchase(data: any | 'null'): Observable<any> {
  //   let headers = this.getHeader();
  //   return this.http
  //     .request(
  //       "post",
  //       Location.joinWithSlash(`${this.basePath}/api/po_to_purchase`,
  //       {
  //         observe: "response",
  //         headers,
  //         body: JSON.stringify(data),
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // //Get Enable Resturant Pos
  // getEnableResturantPos(): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "get",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/ChechEnableResturantPos`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // getpendingDelivery(): Observable<any> {
  //   let headers = this.getHeader();
  //   return this.http
  //     .request(
  //       "get",
  //       Location.joinWithSlash(`${this.basePath}/api/pending_delivery`,
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // getPendingOrders(): Observable<any> {
  //   let headers = this.getHeader();
  //   return this.http
  //     .request(
  //       "get",
  //       Location.joinWithSlash(`${this.basePath}/api/pending_orders`,
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  //emailSendForInvoce
  emailSendForInvoce(emailInfo: any | 'null'): Observable<any> {
    let headers = this.getHeader();
    return this.http
      .post<any>(
        `${this.basePath}/api/emailInfoSendforTemplate`
        , emailInfo,
        {
          headers
        }
      )
  }

  //Company email settings
  editCompanyEmailSettingsInfo(id: string | 'null', emailInfo: string | 'null'): Observable<any> {
    let headers = this.getHeader();

    return this.http
      .put<any>(
        `${this.basePath}/api/CompanyEmailSettings/${encodeURIComponent(id)}`
        , emailInfo,
        {
          headers
        }
      )
  }

  //Company email settings
  editCompanyMapSettingsInfo(id: string | 'null', mapInfo: string | 'null'): Observable<any> {
    let headers = this.getHeader();

    return this.http
      .put<any>(
        `${this.basePath}/api/CompanyMapSettingsInfo/${encodeURIComponent(id)}`
        , mapInfo,
        {
          headers
        }
      )
  }

  // //Reorder API service
  // getReorderList(): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "get",
  //       Location.joinWithSlash(`${this.basePath}/api/reorder`,
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // getCheckedItem(id:any) {
  //   let headers = this.getHeader();
  //   return this.http
  //     .request(
  //       "get",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/getCheckedItem/${encodeURIComponent(id)}`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }


  //Customer Items Report API Service
  CustomerItemsReport(
    from_date: any,
    to_date: any,
    ledger_id: any,
    item_id: any
  ) {
    let headers = this.getHeader();
    from_date.setHours(0, 0, 0, 0);
    to_date.setHours(23, 59, 59, 59);

    return this.http
      .get<any>(
        `${this.basePath}/api/CustomerItemsReport/${encodeURIComponent(
          from_date
        )}/${encodeURIComponent(to_date)}/${encodeURIComponent(
          ledger_id
        )}/${encodeURIComponent(item_id)}`
        ,
        {
          headers,
        }
      )
  }
  //Stock Transfer API service
  addStockTransfer(data: models.TransactionObj | 'null'): Observable<any> {
    let headers = this.getHeader();

    return this.http
      .post<any>(`${this.basePath}/api/StockTransfer`, data,
        {
          headers
        }
      )
  }
  getStockTransfer(id: string | 'null'): Observable<any> {
    let headers = this.getHeader();

    return this.http
      .get<any>(
        `${this.basePath}/api/StockTransfer/${encodeURIComponent(id)}`
        ,
        {
          headers,
        }
      )
  }

  editStockTransfer(id: string | 'null', data: models.TransactionObj | 'null'): Observable<any> {
    let headers = this.getHeader();

    return this.http
      .put<any>(
        `${this.basePath}/api/StockTransfer/${encodeURIComponent(id)}`
        , data,
        {
          headers
        }
      )
  }

  deleteStockTransfer(id: string | 'null'): Observable<any> {
    let headers = this.getHeader();

    return this.http
      .delete<any>(
        `${this.basePath}/api/StockTransfer/${encodeURIComponent(id)}`
        ,
        {
          headers,
        }
      )
  }


  // //Transaction MEMO API service
  addMemo(data: any): Observable<any> {
    let headers = this.getHeader();
    return this.http.post<any>(`${this.basePath}/api/Memo`, data, { headers });

  }

  getTransactionsMemo(): Observable<any> {
    let headers = this.getHeader();
    return this.http
      .get<any>(`${this.basePath}/api/Memo`, {
        headers,
      })

  }

  openMemo(transaction_id: any): Observable<any> {
    let headers = this.getHeader();
    return this.http
      .get<any>(
        `${this.basePath}/api/Memo/${encodeURIComponent(transaction_id)}`
        ,
        {
          headers,
        }
      )
  }

  getMemoItems(transaction_id: any): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(`${this.basePath}/api/getMemoItems/${encodeURIComponent(transaction_id)}`, { headers });

  }
  deleteMemos(transaction_id: any): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(`${this.basePath}/api/Memo/${encodeURIComponent(transaction_id)}`, { headers });

  }
  //Licence API service
  getLicenceDetails(): Observable<any> {
    let headers = this.getHeader();

    return this.http
      .get<any>(`${this.basePath}/api/Licence`,
        {
          headers,
        }
      )
  }

  uploadLicence(data: any) {
    let headers = new HttpHeaders();
    headers = headers.set("Accept", "multipart/form-data");
    this.cookieValue = this.cookieService.get("SESSIONID");
    // headers = headers.set("Authorization", "bearer " + this.cookieValue);
    headers = headers.set("Authorization", "Bearer " + this.cookieValue)

    return this.http
      .post<any>(`${this.basePath}/api/Licence`,
        data, {
        headers
      }
      )
  }
  // gst1 reports

  getnSale(from_date: any | 'null', to_date: any | 'null', type_id: string | 'null'): Observable<any> {
    let headers = this.getHeader();
    from_date.setHours(0, 0, 0, 0);
    to_date.setHours(23, 59, 59, 59);
    return this.http
      .get<any>(
        `${this.basePath}/api/getnSale/${encodeURIComponent(from_date)}/${encodeURIComponent(
          to_date
        )}`
        ,
        {
          headers,
        }
      )
  }

  gstr1SaleRetuen(
    from_date: any | 'null',
    to_date: any | 'null',
    type_id: string | 'null'
  ) {
    let headers = this.getHeader();
    from_date.setHours(0, 0, 0, 0);
    to_date.setHours(23, 59, 59, 59);
    return this.http
      .get<any>(
        `${this.basePath}/api/gstr1SaleRetuen/${encodeURIComponent(
          from_date
        )}/${encodeURIComponent(to_date)}`
        ,
        {
          headers,
        }
      )
  }

  addInvoiceTrans(data: models.TransactionObj | 'null'): Observable<any> {
    let headers = this.getHeader();
    return this.http
      .post<any>(`${this.basePath}/api/addTransFields`,
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
  }
  getInvoiceTrans(id: any): Observable<any> {
    let headers = this.getHeader();
    return this.http
      .get<any>(
        `${this.basePath}/api/getTransFields/${encodeURIComponent(id)}`
        ,
        {
          headers,
        }
      )
  }

  updateInvoiceTrans(data: models.TransactionObj | 'null', id: any): Observable<any> {
    let headers = this.getHeader();
    return this.http
      .put<any>(
        `${this.basePath}/api/updateTransField/${encodeURIComponent(id)}`
        , data,
        {
          headers
        }
      )
  }

  getEnabledInvoiceField(id: any): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(`${this.basePath}/api/getEnabledFields/${encodeURIComponent(id)}`, { headers })
  }
  // Account Map Category API service
  getAppCategorySetting(): Observable<any> {
    let headers = this.getHeader();

    return this.http
      .get<any>(`${this.basePath}/api/AppCategorysetting`,
        {
          headers
        }
      )
  }

  getDefaultCategory(): Observable<any> {
    let headers = this.getHeader();

    return this.http
      .get<any>(`${this.basePath}/api/getDefaultCategory`,
        {
          headers
        }
      )
  }

  editAppCategory(data: any): Observable<any> {
    let headers = this.getHeader();
    return this.http
      .put<any>(`${this.basePath}/api/updateAppCategory`, data,
        {
          headers
        }
      )
  }
  //Convert GRN to Purchase API service
  convertGRNtoPurchase(id: string | 'null'): Observable<any> {
    let headers = this.getHeader();
    return this.http
      .get<any>(
        `${this.basePath}/api/GRNtoPurchase/${encodeURIComponent(id)}`
        ,
        {
          headers
        }
      )
  }
  //Convert GRN to Purchase API service
  convertDOtoInvoice(id: string | 'null'): Observable<any> {
    let headers = this.getHeader();
    return this.http
      .get<any>(
        `${this.basePath}/api/DOtoInvoice/${encodeURIComponent(id)}`
        ,
        {
          headers
        }
      )
  }
  //get multiple GRN items list
  getMultipleGRNItems(grn_id_list: string | 'null', ledger_id: any): Observable<any> {
    let headers = this.getHeader();

    return this.http
      .get<any>(
        `${this.basePath}/api/MultipleGRNItems/${encodeURIComponent(
          grn_id_list
        )}/${encodeURIComponent(ledger_id)}`
        ,
        {
          headers
        }
      )
  }
  getGRNnotToPurchase(ledger_id: string | 'null'): Observable<any> {
    let headers = this.getHeader();

    return this.http
      .get<any>(
        `${this.basePath}/api/GRNnotPurchased/${encodeURIComponent(ledger_id)}`
        ,
        {
          headers
        }
      )
  }

  //============================item fields value get=====================================
  getItemFieldsValue(id: any): Observable<any> {
    let headers = this.getHeader();
    return this.http
      .get<any>(
        `${this.basePath}/api/getAdditionItemFields/${encodeURIComponent(id)}`
        ,
        {
          headers
        }
      )
  }

  getEnabledItemAdditionField(id: any): Observable<any> {
    let headers = this.getHeader();
    return this.http
      .get<any>(
        `${this.basePath}/api/getEnabledItemAddtionalFields/${encodeURIComponent(id)}`
        ,
        {
          headers
        }
      )
  }

  updateItemFieldsValue(data: models.TransactionObj | 'null', id: any): Observable<any> {
    let headers = this.getHeader();
    return this.http
      .put<any>(
        `${this.basePath}/api/updateItemFieldsValue/${encodeURIComponent(id)}`
        , data,
        {
          headers
        }
      )
  }

  getLocationByCb(loc_id: string | 'null', selected_item_id: string | 'null'): Observable<any> {
    let headers = this.getHeader();

    return this.http
      .get<any>(
        `${this.basePath}/api/LocationByCb/${encodeURIComponent(loc_id)}/${encodeURIComponent(
          selected_item_id
        )}`
        ,
        {
          headers
        }
      )
  }

  getAllVoucherListByDay(from_date: any | 'null', to_date: any | 'null'): Observable<any> {
    let headers = this.getHeader();
    from_date.setHours(0, 0, 0, 0);
    to_date.setHours(23, 59, 59, 59);
    return this.http
      .get<any>(
        `${this.basePath}api/getReportByDate/${encodeURIComponent(
          from_date
        )}/${encodeURIComponent(to_date)}`
        ,
        {
          headers
        }
      )
  }

  //------------------GET DUE LIST--------------------------------
  // getDueList(from_date: any | 'null', to_date: any | 'null', onlyDue: any): Observable<any> {
  //   let headers = this.getHeader();
  //   return this.http.request('get', Location.joinWithSlash(`${this.basePath}/api/DueList/${encodeURIComponent(from_date)}/${encodeURIComponent(to_date)}/${encodeURIComponent(onlyDue)}`, {
  //     observe: 'response',
  //     headers
  //   })
  //     .map(response => {
  //       switch (response.status) {
  //         case 200: {
  //           return response.body;
  //         }
  //       }
  //     })
  // }

  getDueList(
    from_date: any | 'null',
    to_date: any | 'null',
    onlyDue: any,
    user_id: any,
    onlync: any
  ) {
    // from_date.setHours(0, 0, 0, 1);
    // to_date.setHours(23, 59, 59, 59);
    let headers = this.getHeader();
    return this.http
      .get<any>(
        `${this.basePath}/api/DueList/${encodeURIComponent(from_date)}/${encodeURIComponent(
          to_date
        )}/${encodeURIComponent(onlyDue)}/${encodeURIComponent(
          user_id
        )}/${encodeURIComponent(onlync)}`
        ,
        {
          headers
        }
      )
  }

  getPurchaseSummary(from_date: any | 'null', to_date: any | 'null', onlyDue: any): Observable<any> {
    let headers = this.getHeader();
    // Ensure from_date and to_date are Date objects
    if (from_date && typeof from_date !== 'object') {
      from_date = new Date(from_date); // Convert from string to Date
    }
    if (to_date && typeof to_date !== 'object') {
      to_date = new Date(to_date); // Convert from string to Date
    }

    from_date.setHours(0, 0, 0, 1);
    to_date.setHours(23, 59, 59, 59);
    return this.http
      .get<any>(
        `${this.basePath}/api/PurchaseSummary/${encodeURIComponent(
          from_date
        )}/${encodeURIComponent(to_date)}/${encodeURIComponent(onlyDue)}`
        ,
        {
          headers
        }
      )
  }
  getItemSalesReport(from_date: any | 'null', to_date: any | 'null'): Observable<any> {
    let headers = this.getHeader();
    // Ensure from_date and to_date are Date objects
    if (from_date && typeof from_date !== 'object') {
      from_date = new Date(from_date); // Convert from string to Date
    }
    if (to_date && typeof to_date !== 'object') {
      to_date = new Date(to_date); // Convert from string to Date
    }

    from_date.setHours(0, 0, 0, 1);
    to_date.setHours(23, 59, 59, 59);
    return this.http
      .get<any>(
        `${this.basePath}/api/ItemSalesReport/${encodeURIComponent(
          from_date
        )}/${encodeURIComponent(to_date)}`
        ,
        {
          headers
        }
      )
  }

  getCustomerItemRate(ledger_id: any | 'null'): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(`${this.basePath}/api/CustomerItemRate/${encodeURIComponent(ledger_id)}`, { headers });

  }
  // //Multicompany API Service
  // getCompanyList(tenent_id: string | 'null'): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "get",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/MultiCompanyList/${encodeURIComponent(tenent_id)}`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // addCompany(data: any, tenent_id: string | 'null'): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "post",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/MultiCompanyList/${encodeURIComponent(tenent_id)}`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //         body: JSON.stringify(data),
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // getCompany(id: string | 'null'): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "get",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/MultiCompany/${encodeURIComponent(id)}`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // deleteCompany(id: string | 'null'): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "delete",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/MultiCompany/${encodeURIComponent(id)}`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // editCompany(id: string | 'null', data: any): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "put",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/MultiCompany/${encodeURIComponent(id)}`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //         body: JSON.stringify(data),
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }
  // getFyList(company_id: string | 'null'): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "get",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/companyFY/${encodeURIComponent(company_id)}`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }
  // getFy(fy_id: string | 'null'): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "get",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/FY/${encodeURIComponent(fy_id)}`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }
  // closeFy(company_id: string | 'null', fy_id: string | 'null', data: any): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "post",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/CloseFY/${encodeURIComponent(company_id)}/${encodeURIComponent(
  //           fy_id
  //         )}`
  //       ),
  //       {
  //         observe: "response",
  //         body: JSON.stringify(data),
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }
  // //Subdomain Details Route
  // getSubdomainDetails(): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "get",
  //       Location.joinWithSlash(`${this.basePath}/api/SubdomainDetails`,
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // generate_token(code: any): Observable<any> {
  //   let headers = this.getHeader();
  //   return this.http
  //     .request(
  //       "post",
  //       Location.joinWithSlash(`${this.basePath}/api/generate_token`,
  //       {
  //         observe: "response",
  //         headers,
  //         body: { code: code },
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }




  getLedgerClosingBalance(ledger_id: any): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(`${this.basePath}/api/LedgerClosingBalance/${encodeURIComponent(ledger_id)}`, { headers });

  }
  // getActionPermissionByKey(data: any): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "post",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/MenuActionPermissionByKey`
  //       ),
  //       {
  //         observe: "response",
  //         body: JSON.stringify(data),
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }
  // updateSessionAllowLogin(isAllow: any, table_id: any): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "get",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/SessionAllowLogin/${encodeURIComponent(
  //           table_id
  //         )}/${encodeURIComponent(isAllow)}`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // updateActiveTable_inactive(active: any, table_id: any): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "put",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/clearTable/${encodeURIComponent(table_id)}/${encodeURIComponent(
  //           active
  //         )}`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }
  // getWebActiveTable(table_id: any): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "get",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/getActiveTable/${encodeURIComponent(table_id)}`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // SessionByTableId(table_id: any): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "get",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/SessionByTableId/${encodeURIComponent(table_id)}`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // getInvoiceByBillNo(id: any | 'null'): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "get",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/InvoiceByBillNo/${encodeURIComponent(id)}`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // // --------tableTransfer----------------
  // transferTable(data: any): Observable<any> {
  //   let headers = this.getHeader();
  //   return this.http
  //     .request(
  //       "get",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/TransferTable/${encodeURIComponent(
  //           data.table_id_1
  //         )}/${encodeURIComponent(data.table_id_2)}`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // //------------activity log --------------
  getActivityLogList(from_date: any | 'null', to_date: any | 'null'): Observable<any> {
    // Ensure from_date and to_date are Date objects
    if (from_date && typeof from_date !== 'object') {
      from_date = new Date(from_date); // Convert from string to Date
    }
    if (to_date && typeof to_date !== 'object') {
      to_date = new Date(to_date); // Convert from string to Date
    }

    from_date.setHours(0, 0, 0, 1);
    to_date.setHours(23, 59, 59, 59);
    let headers = this.getHeader();
    return this.http
      .get<any>(
        `${this.basePath}/api/ActivityLog/${encodeURIComponent(
          from_date
        )}/${encodeURIComponent(to_date)}`
        ,
        {
          headers
        }
      )
  }

  // // --------------customerDetailsReport----------
  // customerDetailsReport(): Observable<any> {
  //   let headers = this.getHeader();
  //   return this.http
  //     .request(
  //       "get",
  //       Location.joinWithSlash(`${this.basePath}/api/customersDetails`,
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // private theme = new BehaviorSubject<boolean>(false);

  // settheme(n: any) {
  //   this.theme.next(n);
  // }
  // gettheme(): Observable<any> {
  //   return this.theme.asObservable();
  // }



  private resultDataSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  private resultData$: Observable<any> = this.resultDataSubject.asObservable();

  setResultData(data: any) {
    this.resultDataSubject.next(data);
  }

  getResultData(): Observable<any> {
    setTimeout(() => { }, 0);
    return this.resultData$;
  }

  getRestaurantCategoryList(): Observable<any> {
    let headers = this.getHeader();

    return this.http
      .get<any>(
        `${this.basePath}/api/GetRestaurantCategory`
        ,
        {
          headers
        }
      )
  }

  getRestaurantMenuItemList(): Observable<any> {
    let headers = this.getHeader();

    return this.http
      .get<any>(
        `${this.basePath}/api/GetRestaurantMenuItems`
        ,
        {
          headers
        }
      )
  }

  updatewaterwaiter(table_id: any): Observable<any> {
    let headers = this.getHeader();

    return this.http
      .post<any>(
        `${this.basePath}/api/doneWaterOrWaiter/${encodeURIComponent(table_id)}`
        ,
        {
          headers
        }
      )
  }
  // themes
  importCoverImages(data: any) {
    let headers = new HttpHeaders();
    headers = headers.set("Accept", "multipart/form-data");
    this.cookieValue = this.cookieService.get("SESSIONID");
    // headers = headers.set("Authorization", "bearer " + this.cookieValue);
    headers = headers.set("Authorization", "Bearer " + this.cookieValue)

    return this.http
      .post<any>(`${this.basePath}/api/ImportCoverImage`,
        data, {
        headers
      }
      )
  }
  importImagesLogo(data: any) {
    let headers = new HttpHeaders();
    headers = headers.set("Accept", "multipart/form-data");
    this.cookieValue = this.cookieService.get("SESSIONID");
    // headers = headers.set("Authorization", "bearer " + this.cookieValue);
    headers = headers.set("Authorization", "Bearer " + this.cookieValue)

    return this.http
      .post<any>(`${this.basePath}/api/ImportLogoImages`,
        data, {
        headers
      }
      )
  }
  getCoverList(): Observable<any> {
    let headers = this.getHeader();

    return this.http
      .get<any>(`${this.basePath}/api/getCoverImage`,
        {
          headers
        }
      )
  }
  getLogoList(): Observable<any> {
    let headers = this.getHeader();

    return this.http
      .get<any>(`${this.basePath}/api/getLogoImage`,
        {
          headers
        }
      )
  }
  deletethemecover(id: string | 'null'): Observable<any> {
    let headers = this.getHeader();

    return this.http
      .delete<any>(
        `${this.basePath}/api/getCoverImage/${encodeURIComponent(id)}`
        ,
        {
          headers
        }
      )
  }
  deleteLogo(id: string | 'null'): Observable<any> {
    let headers = this.getHeader();

    return this.http
      .delete<any>(
        `${this.basePath}/api/getLogoImage/${encodeURIComponent(id)}`
        ,
        {
          headers,
        }
      )
  }
  //======================Get Enable Website===========================
  getWebsiteEnableStatus(): Observable<any> {
    let headers = this.getHeader();
    return this.http
      .get<any>(
        `${this.basePath}/api/getWebsiteEnableStatus`
        ,
        {
          headers,
        }
      )
  }

  // //======================Get Enable Wera===========================

  // // ------------------website---------------------



  //Disposal
  getDisposalList(): Observable<any> {
    let headers = this.getHeader();
    return this.http
      .get<any>(`${this.basePath}/api/DisposalCategorylist`,
        {
          headers
        }
      )
  }
  getDisposalItemList(): Observable<any> {
    let headers = this.getHeader();
    return this.http
      .get<any>(`${this.basePath}/api/DisposalItemlist`,
        {
          headers
        }
      )
  }

  // // -----------------------------getTenentCompanyInfo------------------------------------
  getTenentCompanyInfo(companyName: string | 'null'): Observable<any> {
    let headers = this.getHeader();
    return this.http
      .get<any>(
        `${this.basePath}/api/getTenentCompanyInfo/${encodeURIComponent(companyName)}`
        ,
        {
          headers
        }
      )
  }



  ncValidation(): Observable<any> {
    let headers = this.getHeader();
    return this.http.get<any>(`${this.basePath}/api/sendmail`, { headers });

  }

  verifyemailotp(data: any) {
    let headers = this.getHeader();
    return this.http.post<any>(`${this.basePath}/api/verifyotp`, data, { headers });

  }

  // //Company sms settings-----------------------------------
  editCompanySmsSettingsInfo(id: string | 'null', smsInfo: string | 'null'): Observable<any> {
    let headers = this.getHeader();

    return this.http
      .put<any>(
        `${this.basePath}/api/CompanySMSSettingsInfo/${encodeURIComponent(id)}`
        , smsInfo,
        {
          headers
        }
      )
  }

  //twilio sms balance -------------
  // curl -G https://api.twilio.com/2010-04-01/Accounts/AC3a9483e1d9d2edd8f55f1a958a2af30c/Balance.json \ -u "AC3a9483e1d9d2edd8f55f1a958a2af30c:97a5a7bd77112ba5bc86c5d83c7c79d5"

  getTwilioBalance(smsCredentials: any): Observable<any> {
    const twilioAccountSid = smsCredentials.twilio_sid;
    const authToken = smsCredentials.twilio_token;
    const url = `https://api.twilio.com/2010-04-01/Accounts/${twilioAccountSid}/Balance.json`;

    const headers = new HttpHeaders({
      Authorization: `Basic ${btoa(`${twilioAccountSid}:${authToken}`)}`,
    });

    return this.http.get(url, { headers });
  }

  // =====================smsLowBalanceEmail ===============
  smsLowBalanceEmail(data: any): Observable<any> {
    let headers = this.getHeader();
    return this.http
      .post<any>(`${this.basePath}/api/smsLowBalanceEmail`,
        data, {
        headers
      }
      )
  }


  // // getTableInfo(table_id: any,getItems:any,getCategory:any,getSubCategory:any,getDisposalitems:any,getDisposalCategory:any): Observable<any> {
  // //   let headers = this.getHeader();
  // //   return this.http.request('get', Location.joinWithSlash(`${this.basePath}/api/getTableInfo/${encodeURIComponent(table_id)}/${encodeURIComponent(getItems)}/${encodeURIComponent(getCategory)}/${encodeURIComponent(getSubCategory)}/${encodeURIComponent(getDisposalitems)}/${encodeURIComponent(getDisposalCategory)}`, {
  // //     observe: 'response',
  // //     headers
  // //   })
  // //     .map(response => {
  // //       switch (response.status) {
  // //         case 200: {
  // //           return response.body;
  // //         }
  // //       }
  // //     })
  // // }
  // getTableInfo(table_id: any, data: any): Observable<any> {
  //   let headers = this.getHeader();
  //   return this.http
  //     .request(
  //       "post",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/getTableInfo/${encodeURIComponent(table_id)}`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //         body: JSON.stringify(data),
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // // -------------------get Inetial seting ------------
  // getInitialSetting(): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "get",
  //       Location.joinWithSlash(`${this.basePath}/api/InitialSetting`,
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // // --------------------- Table reservation ------
  // getUnApprovedReservedTables(): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "get",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/getUnApprovedReservedTables`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }
  // getUnApprovedReservedTableswithDate(date: any): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "get",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/getUnApprovedReservedTableswithDate/${encodeURIComponent(date)}`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }
  // getTablesWithGroup(): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "get",
  //       Location.joinWithSlash(`${this.basePath}/api/getTablesWithGroup`,
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // submitReservationTable(data: any): Observable<any> {
  //   let headers = this.getHeader();
  //   return this.http
  //     .request(
  //       "post",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/submitReservationTable`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //         body: JSON.stringify(data),
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // updateReservationTable(id: string | 'null', data:any | 'null'): Observable<any> {
  //   let headers = this.getHeader();
  //   return this.http
  //     .request(
  //       "put",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/updateReservationTable/${encodeURIComponent(id)}`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //         body: JSON.stringify(data),
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // deleteReservedTablesDetails(code: string | 'null'): Observable<any> {
  //   let headers = this.getHeader();
  //   return this.http
  //     .request(
  //       "delete",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/deleteReservedTablesDetails/${encodeURIComponent(code)}`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // // wera implementations -----------------------------
  // approveWeraKot(id: string | 'null', data:any | 'null'): Observable<any> {
  //   let headers = this.getHeader();
  //   return this.http
  //     .request(
  //       "post",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/approveWeraOrder/${encodeURIComponent(id)}`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //         body: JSON.stringify(data),
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // rejectWeraKot(id: string | 'null', data:any | 'null'): Observable<any> {
  //   let headers = this.getHeader();
  //   return this.http
  //     .request(
  //       "post",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/rejectWeraOrder/${encodeURIComponent(id)}`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //         body: JSON.stringify(data),
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  isDeliveryServiceEnable(): Observable<any> {
    let headers = this.getHeader();
    return this.http
      .get<any>(
        `${this.basePath}/api/isDeliveryServiceEnable`
        ,
        {
          headers,
        }
      )
  }

  // // wera merchat integration ---------------------------------------------------------------

  // weraMerchantRegistration(data:any | 'null'): Observable<any> {
  //   let headers = this.getHeader();
  //   return this.http.post(`${this.microServiceRegistrationUrl}/merchant/merchantRegistration`, data, {
  //     observe: 'response',
  //     headers
  //   })
  //     .map(response => {
  //       switch (response.status) {
  //         case 200: {
  //           return response.body;
  //         }
  //       }
  //     })
  // }

  weraMerchantRegistration(data: any | 'null'): Observable<any> {
    let headers = this.getHeader();
    return this.http
      .post<any>(`${this.basePath}/api/merchantRegistration`,
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
  }

  updateMerchantAdditionalDetails(data: any | 'null'): Observable<any> {
    let headers = this.getHeader();
    return this.http
      .post<any>(
        `${this.basePath}/api/UpdateMerchantSettings`
        , data,
        {
          headers
        }
      )
  }

  postWerraStoreStatus(data: any | 'null'): Observable<any> {
    let headers = this.getHeader();
    return this.http
      .post<any>(`${this.basePath}/api/putStoreONOFF`,
        {
          observe: "response",
          headers,
          body: JSON.stringify(data),
        }
      )
  }

  weraLiveStoreOnOffStatus(): Observable<any> {
    let headers = this.getHeader();
    return this.http
      .post<any>(`${this.basePath}/api/getStoreStatus`,
        {
          observe: "response",
          headers,
        }
      )
  }

  weraSettingData(): Observable<any> {
    let headers = this.getHeader();
    return this.http
      .get<any>(`${this.basePath}/api/getWeraSettingData`,
        {
          observe: "response",
          headers,
        }
      )
  }

  //-------------------wera menu integration  uploadMenuToWera
  getWeraEnableStatus(): Observable<any> {
    let headers = this.getHeader();

    return this.http.get<any>(`${this.basePath}/api/getWeraEnableStatus`, { headers });

  }
  uploadWeraMenu(): Observable<any> {
    let headers = this.getHeader();
    return this.http
      .post<any>(`${this.basePath}/api/uploadMenuToWera`,
        {
          observe: "response",
          headers,
          body: "",
        }
      )
  }

  weraItemOnOFF(data: any): Observable<any> {
    let headers = this.getHeader();
    return this.http
      .post<any>(`${this.basePath}/api/weraItemOnOff`,
        {
          headers
        }
      )
  }
  weraAddOnOnOFF(data: any): Observable<any> {
    let headers = this.getHeader();

    return this.http.post<any>(`${this.basePath}/api/weraAddOnsOnOff`, data, { headers });

  }

  weraSizeOnOff(data: any): Observable<any> {
    let headers = this.getHeader();

    return this.http.post<any>(`${this.basePath}/api/weraSizeOnOff`, data, { headers });
  }

  downloadSwiggyMenu(): Observable<any> {
    let headers = this.getHeader();
    return this.http
      .post<any>(`${this.basePath}/api/downloadSwiggyMenu`,
        {
          observe: "response",
          headers,
        }
      )
  }

  checkFullWeraUploadedMenu(): Observable<any> {
    let headers = this.getHeader();
    return this.http
      .post<any>(`${this.basePath}/api/trackPullMenuStatus`,
        {
          observe: "response",
          headers,
        }
      )
  }
  getWeraDeliveryAgentDetails(transaction_id: any): Observable<any> {
    let headers = this.getHeader();
    return this.http
      .post<any>(
        `${this.basePath}/api/getWeraDeliveryAgentStatus/${encodeURIComponent(
          transaction_id
        )}`
        ,
        {
          headers
        }
      )
  }

  // //Kitchen API Service
  // getKitchenList(): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "get",
  //       Location.joinWithSlash(`${this.basePath}/api/kitchen`,
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // addkitchen(data: models.kitchenObj | 'null'): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "post",
  //       Location.joinWithSlash(`${this.basePath}/api/kitchen`,
  //       {
  //         observe: "response",
  //         headers,
  //         body: JSON.stringify(data),
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // getkitchen(id: string | 'null'): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "get",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/kitchen/${encodeURIComponent(id)}`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // deletekitchen(id: string | 'null'): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "delete",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/kitchen/${encodeURIComponent(id)}`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }

  // editKitchen(id: string | 'null', data: models.kitchenObj | 'null'): Observable<any> {
  //   let headers = this.getHeader();

  //   return this.http
  //     .request(
  //       "put",
  //       Location.joinWithSlash(
  //         `${this.basePath}`,
  //         `/api/kitchen/${encodeURIComponent(id)}`
  //       ),
  //       {
  //         observe: "response",
  //         headers,
  //         body: JSON.stringify(data),
  //       }
  //     ).pipe(
  //       map((response: HttpResponse<any>) => {
  //         switch (response.status) {
  //           case 200: {
  //             return response.body;
  //           }
  //         }
  //       })
  //     );
  // }


  saveNotificationsSettings(data: any) {
    let headers = this.getHeader();

    return this.http
      .post<any>(`${this.basePath}/api/Summary/Notification`, data,
        {
          headers
        }
      )

  }
  setSummaryNotificationStatus(data: any) {
    let headers = this.getHeader();

    return this.http
      .post<any>(
        `${this.basePath}/api/Summary/Notification/Status`
        , data,
        {
          headers
        }
      )

  }

  getNotificationSettings() {
    let headers = this.getHeader();

    return this.http
      .get<any>(`${this.basePath}/api/Summary/Notification`,
        {
          headers
        }
      )

  }
  getSummaryNow(data: any) {
    let headers = this.getHeader();

    return this.http
      .post<any>(`${this.basePath}/api/Summary/Send`, data,
        {
          headers
        }
      )

  }





  fetchContacts(page: number, limit: number): Observable<any> {
    let headers = this.getHeader();

    return this.http
      .get<any>(
        `${this.basePath}/api/crm/contacts?page=${page}&limit=${limit}`
        ,
        {
          headers
        }
      )

  }

  fetchContactByPhone(phone_no: string): Observable<any> {
    let headers = this.getHeader();

    return this.http
      .get<any>(
        `${this.basePath}/api/crm/contacts/phone/${phone_no}`
        ,
        {
          headers
        }
      )

  }

  getOrderHistoryByPhoneNumber(phone_no: string): Observable<any> {
    let headers = this.getHeader();

    return this.http
      .get<any>(
        `${this.basePath}/api/crm/contacts/order-history/${phone_no}`
        ,
        {
          headers
        }
      )

  }

  deleteContact(customerID: number): Observable<any> {
    let headers = this.getHeader();
    return this.http.delete<any>(`${this.basePath}/api/crm/contacts`, {
      headers,
      body: { CustomerID: customerID.toString() }
    });
  }

  addContact(contact: any): Observable<any> {
    let headers = this.getHeader();
    return this.http
      .post<any>(`${this.basePath}/api/crm/contacts`, contact,
        {
          headers
        }
      )
  }

  fetchAllcontacts():Observable<any> {
    let headers = this.getHeader();
    return this.http
      .get<any>(`${this.basePath}/api/crm/AllContacts`,
        {
          headers
        }
      )
    }

  editContact(contact: any): Observable<any> {
    let headers = this.getHeader();
    return this.http
      .put<any>(`${this.basePath}/api/crm/contacts`, contact,
        {
          headers
        }
      )

  }
  importContact(data: any) {
    let headers = this.getHeader();

    return this.http
      .post<any>(`${this.basePath}/api/crm/contacts/import`, data,
        {
          headers
        }
      )

  }

  importContacts(formData: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set("Accept", "multipart/form-data");
    this.cookieValue = this.cookieService.get("SESSIONID");
    // headers = headers.set("Authorization", "bearer " + this.cookieValue);
    headers = headers.set("Authorization", "Bearer1 " + this.cookieValue + " Bearer2 " + this.cookieService.get("refresh_token"))
    return this.http
      .post<any>(`${this.basePath}/api/crm/contacts/import`, formData,
        {
          headers
        }
      )

  }

  searchNumber(contactNo: any): Observable<any> {
    let headers = this.getHeader();
    return this.http
      .post<any>(`${this.basePath}/api/crm/searchnumber`, { contactNo: contactNo },
        {
          headers
        }
      )

  }

  searchDetails(serchterm: any): Observable<any> {
    let headers = this.getHeader();
    return this.http
      .post<any>(`${this.basePath}/api/crm/details`, serchterm,
        {
          headers
        }
      )

  }

  getbirthEvent(type: any): Observable<any> {
    const headers = this.getHeader();

    return this.http
      .get<any>(`${this.basePath}/api/crm/birthday/${type}`,
        {
          headers
        }
      )
  }

  getAnniEvent(type: any) {
    let headers = this.getHeader();

    return this.http
      .get<any>(
        `${this.basePath}/api/crm/annivarsay/${type}`
        ,
        {
          headers
        }
      )

  }

  sendsmsto(phone_no: any): Observable<any> {
    let headers = this.getHeader();
    return this.http
      .post<any>(`${this.basePath}/api/crm/sms`, phone_no,
        {
          headers
        }
      )

  }

  sendEmailTo(emaildId: any): Observable<any> {
    let headers = this.getHeader();
    return this.http
      .post<any>(`${this.basePath}/api/crm/email`, emaildId,
        {
          headers
        }
      )

  }
  editPaymantSetting(id: any | null, data: string | null) {
    let headers = this.getHeader();

    return this.http
      .put<any>(
        `${this.basePath}/api/CompanyPaymentSettings/${encodeURIComponent(id)}`
        , data,
        {
          headers
        }
      )

  }

  updateMuteBtn(display_trans_no: any, is_muted: any) {
    let headers = this.getHeader();

    return this.http
      .put<any>(
        `${this.basePath}/api/websiteMute/${encodeURIComponent(display_trans_no)}`
        , is_muted,
        {
          headers
        }
      )

  }

  NotificationSeen(display_trans_no: any, is_muted: any) {
    let headers = this.getHeader();

    return this.http
      .put<any>(
        `${this.basePath}/api/notificationSeen/${encodeURIComponent(display_trans_no)}`
        , is_muted,
        {
          headers
        }
      )

  }
  kotApproved(id: any, requestBodyy: any) {
    let headers = this.getHeader();

    return this.http
      .put<any>(
        `${this.basePath}/api/websiteKotApproved/${encodeURIComponent(id)}`
        , requestBodyy,
        {
          headers,
        }
      )

  }

  OrderRejected(requestBodyy: any) {
    let headers = this.getHeader();

    return this.http
      .put<any>(`${this.basePath}/api/websiteOrderRejected`, requestBodyy,
        {
          headers
        }
      )

  }

  RejectKot(id: string) {
    let headers = this.getHeader();
    return this.http
      .delete<any>(
        `${this.basePath}/api/rejectionKot/${encodeURIComponent(id)}`
        ,
        {
          headers
        }
      )

  }

  priceListCSVImport(id: any, data: any): Observable<any> {
    let headers = this.getHeader();

    return this.http.post<any>(`${this.basePath}/api/priceListCSVImport/${encodeURIComponent(id)}`, data, { headers });

  }

  //Kitchen API Service
  getKitchenList(active: any): Observable<any> {
    let headers = this.getHeader();
    return this.http.get<any>(`${this.basePath}/api/kitchen/list/${encodeURIComponent(active.active)}`, { headers });
  }

  addkitchen(data: models.kitchenObj | "null"): Observable<any> {
    let headers = this.getHeader();
    return this.http.post<any>(`${this.basePath}/api/kitchen`, data, {
      headers,
    });
  }

  getbill(code: any): Observable<Blob> {
    let headers = new HttpHeaders();

    headers = headers.set("Accept", "application/pdf");
    headers = headers.set("Content-Type", "application/json");
    this.cookieValue = this.cookieService.get("SESSIONID");
    // headers = headers.set("Authorization", "bearer " + this.cookieValue);
    headers = headers.set("Authorization", "Bearer " + this.cookieValue)

    return this.http.get<any>(`${this.basePath}/api/invUrl/${encodeURIComponent(code)}`, {
      headers,
      responseType: "blob" as 'json',
    });
  }



}
