import { Injectable } from "@angular/core";
//import { Workbook } from 'exceljs';


// const Workbook =require('exceljs');
// import * as ExcelJS from 'exceljs'
const ExcelJS = require("exceljs");

@Injectable({
  providedIn: "root",
})
export class ExportService {
  constructor() {}
  generateExcel(
    file_name:any,
    title:any,
    header:any,
    subheader:any,
    data:any,
    columnWidth:any,
    numFmt:any
  ) {
    //Excel Title, Header, Data

    //Create workbook and worksheet
    let workbook = new ExcelJS.Workbook();

    // let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Day Book");
    let topRow = worksheet.addRow([]);
    topRow.font = {
      name: "Arial",
      family: 4,
      size: 12,
      underline: false,
      bold: false,
    };

    //Add Row and formatting
    let titleRow = worksheet.addRow([title]);
    worksheet.mergeCells("A2:F2");
    titleRow.font = {
      name: "Comic Sans MS",
      family: 4,
      size: 16,
      underline: "double",
      bold: true,
    };
    worksheet.getCell("A2").alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    let subTitleRow = worksheet.addRow([subheader]);
    worksheet.mergeCells("A3:F3");
    subTitleRow.font = {
      name: "Comic Sans MS",
      family: 4,
      size: 12,
      underline: false,
      bold: false,
    };
    worksheet.getCell("A3").alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    //Add Header Row
    let headerRow = worksheet.addRow(header);
    headerRow.font = {
      name: "Arial Black",
      family: 4,
      size: 12,
      underline: false,
      bold: true,
    };

    // Cell Style : Fill and Border
    headerRow.eachCell((cell:any, number:any) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });
    
    // Add Data and Conditional Formatting
    data.forEach((d: any) => {
      let row = worksheet.addRow(d);
      row.font = {
        name: "Arial",
        family: 4,
        size: 12,
        underline: false,
        bold: false,
      };
    
      row.eachCell((cell: { alignment: { vertical: string; horizontal: string; }; }, colNumber: number) => {
        // Center align text-based columns
        if ([1, 3, 5].includes(colNumber)) { // Columns: Name, Variant, Option
          cell.alignment = { vertical: "middle", horizontal: "center" };
        }
        // Right align numeric columns
        else if ([4, 6, 7, 8, 9, 10, 11].includes(colNumber)) { // Columns: Price, Quantity, Amounts
          cell.alignment = { vertical: "middle", horizontal: "right" };
        } else {
          cell.alignment = { vertical: "middle", horizontal: "left" }; // Default alignment
        }
      });
    });

// Add an empty row for spacing before total
worksheet.addRow([]);  
let totalRow
// Add Total Row
if(file_name === 'Category Sales Report'){
    totalRow = worksheet.addRow([
    "", "TOTAL", 
    { formula: `SUM(C5:C${data.length + 5})` } // Adjust column index for Net Amount
  ]);
}else {
    totalRow = worksheet.addRow([
    "", "", "", "", "", "", "", "", "", "TOTAL", 
    { formula: `SUM(K5:K${data.length + 5})` } // Adjust column index for Net Amount
  ]);
}

// Apply styling for Total Row
totalRow.font = { bold: true };
totalRow.eachCell((cell: { alignment: { horizontal: string; }; font: { bold: boolean; }; }, colNumber: number) => {
  if (colNumber === 4 || colNumber === 10) { // Assuming TOTAL in 5th and SUM in 11th column
    cell.alignment = { horizontal: "right" };
    cell.font = { bold: true };
  }
});

    
    columnWidth.forEach((col:any) => {
      worksheet.getColumn(col.column).width = col.width;
    });
    numFmt.forEach((nf:any) => {
      worksheet.getColumn(nf.column).numFmt = nf.numFmt;
    });

    // worksheet.getColumn(5).numFmt = '$#,##0.00';
    worksheet.addRow([]);
    //Generate Excel File with given name
    workbook.xlsx
      .writeBuffer()
      .then((data:any) => {
        // let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        // fs.saveAs(blob, file_name+'.xlsx');

        let blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = file_name + new Date().toLocaleDateString() + ".xlsx";
        link.click();
        window.URL.revokeObjectURL(link.href);
      })
      .catch((err:any) => {
        console.log(err);
      });
  }

  generateCSV(file_name:any, header:any, data:any) {
    let csvContent = "data:text/csv;charset=utf-8,";

    // Add header row
    csvContent += header.join(",") + "\n";

    // Add data rows
    data.forEach((rowData:any) => {
      csvContent += rowData.join(",") + "\n";
    });

    // Create CSV file
    let encodedUri = encodeURI(csvContent);
    let link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", file_name + ".csv");
    document.body.appendChild(link); // Required for Firefox
    link.click();
  }

  generateItemsCSV(file_name:any, header:any, data:any) {
    let csvContent = "data:text/csv;charset=utf-8,";

    // Add header row
    csvContent += header.join(",") + "\n";

    // Add data rows
    data.forEach((rowData:any) => {
      csvContent += rowData.join(",") + "\n";
    });

    // Create CSV file
    let encodedUri = encodeURI(csvContent);
    let link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", file_name + ".csv");
    document.body.appendChild(link); // Required for Firefox
    link.click();
  }

  generateCSVar(file_name:any, header:any, data:any) {
    let csvContent = "data:text/csv;charset=utf-8,";

    // Add header row
    csvContent += header.join(",") + "\n";

    // Add data rows
    data.forEach((rowData:any) => {
      csvContent += rowData.join(",") + "\n";
    });

    // Create CSV file
    let encodedUri = encodeURI(csvContent);
    let link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", file_name + ".csv");
    document.body.appendChild(link); // Required for Firefox
    link.click();
  }
}
