import { Injectable } from '@angular/core';
import { TransactionBody } from '../data.modal';


interface AccountMap {
  isTransactionGSTslab: boolean;
  isLocal: boolean;
  gstslab_value: string;
  roundOffSettingVal: string;
}



@Injectable({
  providedIn: 'root'
})
export class CalculationsService {

  constructor() { }



  calculateAmtWithTransTax(body: TransactionBody) {

    const accountSettings: AccountMap = {
      isTransactionGSTslab: localStorage.getItem('isTransactionGSTslab') === 'true',
      isLocal: localStorage.getItem('isLocal') === 'true',
      gstslab_value: localStorage.getItem('gstslab_value') || '0',
      roundOffSettingVal: localStorage.getItem('roundoff') || '2'
    };
    
    const itemsArr = body.items_details;

    // If there are no items, return an empty array
    if (!itemsArr.length){
      return [];
    } 

    //initialize variables
    let taxable_amt = 0,
      total_amount = 0,
      net_amount = 0,
      roundoff_amt = 0,
      gross_amount = 0,
      total_tax = 0,
      sgst_per = 0,
      sgst_amt = 0,
      cgst_per = 0,
      cgst_amt = 0,
      igst_per = 0,
      igst_amt = 0,
      vat_per = 0,
      vat_amt = 0,
      tax_slab = 0,
      vatTotalAmount = 0,
      vatTotalTax = 0,
      service_charge_value = 0,
      sign = '';

    // Check if the transaction is subject to GST slab or not
    if (accountSettings.isTransactionGSTslab === false) {

      service_charge_value = parseFloat(body.service_charge ? body.service_charge : 0);

      // service_charge_per = parseFloat(body.service_per ? body.service_per : 0)
      // Loop through each item
      for (let i = 0; i < itemsArr.length; i++) {
        total_amount += parseFloat(itemsArr[i].amount);
        // Check if the item is subject to GST or VAT
        if (itemsArr[i].tax_mode === 'GST') {
          // Check if the transaction is local or not
          if (accountSettings.isLocal) {
            // Calculate SGST and CGST for local transactions
            itemsArr[i].sgst_per = itemsArr[i].taxslab / 2;
            itemsArr[i].sgst_amt =
              itemsArr[i].amount * (itemsArr[i].sgst_per / 100) || 0;
            itemsArr[i].sgst_amt = Math.round(itemsArr[i].sgst_amt * 100) / 100;
            sgst_amt += itemsArr[i].sgst_amt;
            sgst_per = itemsArr[i].sgst_per;

            itemsArr[i].cgst_per = itemsArr[i].taxslab / 2;
            itemsArr[i].cgst_amt =
              itemsArr[i].amount * (itemsArr[i].cgst_per / 100) || 0;
            itemsArr[i].cgst_amt = Math.round(itemsArr[i].cgst_amt * 100) / 100;

            cgst_amt += itemsArr[i].cgst_amt;
            cgst_per = itemsArr[i].cgst_per;
          } else {
            // Calculate IGST for non-local transactions
            itemsArr[i].igst_per = itemsArr[i].taxslab;
            itemsArr[i].igst_amt =
              itemsArr[i].amount * (itemsArr[i].igst_per / 100) || 0;
            itemsArr[i].igst_amt = Math.round(itemsArr[i].igst_amt * 100) / 100;
            igst_amt += itemsArr[i].igst_amt;
            igst_per = itemsArr[i].igst_per;
          }

          // Calculate the total tax for the item
          itemsArr[i].total_tax =
            (itemsArr[i].sgst_amt || 0) +
            (itemsArr[i].cgst_amt || 0) +
            (itemsArr[i].igst_amt || 0);
        } else {
          // Calculate VAT for the item
          itemsArr[i].vat_per = itemsArr[i].taxslab;
          itemsArr[i].vat_amt =
            itemsArr[i].amount * (itemsArr[i].vat_per / 100) || 0;
          itemsArr[i].vat_amt = Math.round(itemsArr[i].vat_amt * 100) / 100;
          itemsArr[i].total_tax = itemsArr[i].vat_amt || 0;

          vat_per = itemsArr[i].vat_per;
          vat_amt += itemsArr[i].vat_amt;
        }

        // Correcting the total_tax calculation
        itemsArr[i].total_tax =
          (itemsArr[i].sgst_amt || 0) +
          (itemsArr[i].cgst_amt || 0) +
          (itemsArr[i].igst_amt || 0) +
          (itemsArr[i].vat_amt || 0);

        // Calculate the taxable amount for the item
        itemsArr[i].taxable_amt =
          itemsArr[i].amount - (itemsArr[i].discount_amt || 0);

        // Calculate the net amount for the item
        itemsArr[i].net_amount =
          itemsArr[i].taxable_amt + itemsArr[i].total_tax;
      }



      // Calculate the total taxable amount
      taxable_amt = total_amount - (body.discount_amt || 0);


      // Calculate the total tax
      total_tax = itemsArr.reduce((acc: any, item: any) => acc + item.total_tax, 0);
      
      // Calculate the gross amount
      gross_amount =
        taxable_amt +
        total_tax +
        (body.other1_amt || 0) +
        (body.other2_amt || 0) +
        service_charge_value;

      gross_amount = Math.round(gross_amount * 100) / 100;
      // Calculate the roundoff amount
      roundoff_amt = gross_amount - Math.floor(gross_amount);
      roundoff_amt = Math.round(roundoff_amt * 100) / 100;

      if (accountSettings.roundOffSettingVal == "2") {
        // Round after two decimal places
        net_amount = Math.round(gross_amount * 100) / 100; // Round to two decimal places
        roundoff_amt = Math.round((net_amount - gross_amount) * 100) / 100; // Calculate round-off amount

      } else if (accountSettings.roundOffSettingVal == "1") {
        // Round after one decimal place
        net_amount = Math.round(gross_amount * 10) / 10; // Round to one decimal place
        roundoff_amt = Math.round((net_amount - gross_amount) * 10) / 10; // Calculate round-off amount

      } else {
        // General round-off calculation
        if (roundoff_amt < 0.5) {
          net_amount = gross_amount - roundoff_amt;
          net_amount = Math.round(net_amount);
          roundoff_amt = -roundoff_amt;
          sign = "fa-minus";
        } else {
          roundoff_amt = 1 - roundoff_amt;
          roundoff_amt = Math.round(roundoff_amt * 100) / 100;
          net_amount = gross_amount + roundoff_amt;
          net_amount = Math.round(net_amount);
          sign = "fa-plus";
        }
      }
    } else {
      // If the transaction is subject to GST slab
      total_amount = 0;
      tax_slab = parseInt(body.taxslab);
      vatTotalAmount = 0;
      vatTotalTax = 0;
      
      service_charge_value = parseFloat(body.service_charge ? body.service_charge : 0);

      for (let i = 0; i < itemsArr.length; i++) {
        if (itemsArr[i].tax_mode === 'GST') {
          // Calculate the total amount for GST items
          total_amount += parseFloat(itemsArr[i].amount);
        } else {
          // Calculate VAT for the item
          itemsArr[i].vat_per = itemsArr[i].taxslab;
          itemsArr[i].vat_amt =
            itemsArr[i].amount * (itemsArr[i].vat_per / 100) || 0;
          itemsArr[i].vat_amt = Math.round(itemsArr[i].vat_amt * 100) / 100;
          itemsArr[i].total_tax = itemsArr[i].vat_amt || 0;

          itemsArr[i].total = itemsArr[i].amount + itemsArr[i].total_tax;
          vat_per = itemsArr[i].taxslab;
          vat_amt += itemsArr[i].total_tax;
          vatTotalTax += itemsArr[i].total_tax;
          vatTotalAmount += itemsArr[i].amount;
        }
      }

      // Calculate the taxable amount
      taxable_amt = total_amount - (body.discount_amt || 0);

      // Calculate SGST and CGST
      sgst_per = tax_slab / 2;
      sgst_amt = taxable_amt * (sgst_per / 100);
      sgst_amt = Math.round(sgst_amt * 100) / 100;
      cgst_per = tax_slab / 2;
      cgst_amt = taxable_amt * (cgst_per / 100);
      cgst_amt = Math.round(cgst_amt * 100) / 100;

      const tax_amt = sgst_amt + cgst_amt + igst_amt;
      total_tax = sgst_amt + cgst_amt + igst_amt + vatTotalTax;
      
      // Calculate the gross amount
      gross_amount =
        taxable_amt +
        vatTotalAmount +
        tax_amt +
        vatTotalTax +
        (body.other1_amt || 0) +
        (body.other2_amt || 0) +
        service_charge_value;
      gross_amount = Math.round(gross_amount * 100) / 100;
      // Calculate the roundoff amount
      roundoff_amt = gross_amount - Math.floor(gross_amount);
      roundoff_amt = Math.round(roundoff_amt * 100) / 100;
      if (accountSettings.roundOffSettingVal == "2") {
        // Round after two decimal places
        net_amount = Math.round(gross_amount * 100) / 100; // Round to two decimal places
        roundoff_amt = Math.round((net_amount - gross_amount) * 100) / 100; // Calculate round-off amount

      } else if (accountSettings.roundOffSettingVal == "1") {
        // Round after one decimal place
        net_amount = Math.round(gross_amount * 10) / 10; // Round to one decimal place
        roundoff_amt = Math.round((net_amount - gross_amount) * 10) / 10; // Calculate round-off amount

      } else {
        // General round-off calculation
        if (roundoff_amt < 0.5) {
          net_amount = gross_amount - roundoff_amt;
          net_amount = Math.round(net_amount);
          roundoff_amt = -roundoff_amt;
          sign = "fa-minus";
        } else {
          roundoff_amt = 1 - roundoff_amt;
          roundoff_amt = Math.round(roundoff_amt * 100) / 100;
          net_amount = gross_amount + roundoff_amt;
          net_amount = Math.round(net_amount);
          sign = "fa-plus";
        }
      }
    }

    // Collecting the values into an object
    body.taxable_amt = taxable_amt + vatTotalAmount;
    body.service_charge = service_charge_value;
    body.total_amount = total_amount + vatTotalAmount;
    body.net_amount = net_amount;
    body.roundoff_amt = roundoff_amt;
    body.gross_amount = gross_amount;
    body.total_tax = total_tax;
    body.sgst_per = sgst_per;
    body.sgst_amt = sgst_amt;
    body.cgst_per = cgst_per;
    body.cgst_amt = cgst_amt;
    body.igst_per = igst_per;
    body.igst_amt = igst_amt;
    body.vat_per = vat_per;
    body.vat_amt = vat_amt;
    body.tax_slab = tax_slab;
    body.sign = sign;

    // Return the object
    return body;

  };

}
